<template>
  <div :class="classes">
    <v-select
      :id="id"
      ref="multipleProperties"
      v-model="property"
      :options="options"
      :label="label"
      multiple
      :disabled="isDisabled || isLoading"
      :loading="isLoading"
      :placeholder="
        isTopic
          ? $t('audience.topics.selectTopics')
          : $t('selectProperties')
      "
      class="form__select multiple-properties"
      :class="`${
        property.length === options.length && property.length > tagsToShow
          ? allSelectedClasses
          : ''
      } ${invalidInput ? 'form__input--invalid' : ''}`"
      :deselect-from-dropdown="true"
      :close-on-select="false"
      :autoscroll="false"
      @open="openSelect"
      @close="closeSelect"
      @input="updateData(), updateSelection()"
    >
      <template #selected-option="option">
        <span
          v-if="
            property.length === options.length && property.length > tagsToShow
          "
        >
          {{ $t('All') }}
        </span>
        <span
          v-else-if="
            property.indexOf(property.find((prop) => prop.id === option.id)) <
            tagsToShow
          "
        >
          {{ option[label] }}
        </span>
        <span
          v-else-if="option.id === property[tagsToShow].id"
          id="properties-tooltip"
        >
          <span
            >(+{{ property.length - tagsToShow }} {{ $t('other')
            }}<span v-if="property.length - tagsToShow !== 1">s</span>)</span
          >
          <b-tooltip
            target="properties-tooltip"
            triggers="hover"
            placement="right"
          >
            <div v-for="(item, index) in property" :key="index">
              <span v-if="index >= tagsToShow">{{ item[label] }}</span>
            </div>
          </b-tooltip>
        </span>
      </template>
      <template #option="option">
        <div class="option__wrapper">
          <b-form-checkbox
            v-model="option.isSelected"
            class="option__checkbox"
          />
          <span>{{ option[label] }}</span>
        </div>
      </template>
      <template #list-header>
        <div class="actions__container">
          <span @click="selectAll()">Select All</span>
          <div class="dot" />
          <span @click="clearSelection()">Clear</span>
        </div>
      </template>
      <template #spinner="{ loading }">
        <LoadingBar v-if="loading" classList='small mr-1'/>
      </template>
    </v-select>
  </div>
</template>

<!-- eslint-disable no-param-reassign -->
<script>
import {debounce} from '@/utils'
import {BFormCheckbox, BTooltip} from 'bootstrap-vue'
import {getCurrentInstance, onMounted, ref} from 'vue'
import vSelect from 'vue-select'
import LoadingBar from './LoadingBar.vue'

export default {
  components: {
    vSelect,
    LoadingBar,
    BTooltip,
    BFormCheckbox,
  },
  props: {
    id: {
      type: [String],
    },
    options: {
      type: [Array],
    },
    selected: {
      type: [Array],
      default: () => [],
    },
    tagsToShow: {
      type: [Number],
      default: 1,
    },
    label: {
      type: [String],
      default: 'name',
    },
    classes: {
      type: [Array],
      default: () => ['header__wrapper-input', 'multiple-properties--one'],
    },
    allSelectedClasses: {
      type: [Array],
      default: () => ['all-properties'],
    },
    invalidInput: {
      type: [Boolean],
      default: false,
    },
    noDebounce: {
      type: [Boolean],
      default: false,
    },
    isTopic: {
      type: [Boolean],
      default: false,
    },
    isDisabled: {
      type: [Boolean],
      default: false,
    },
    isLoading: {
      type: [Boolean],
      default: false
    }
  },
  emits: ['update-data'],
  setup(props, ctx) {
    const vm = getCurrentInstance().proxy
    const property = ref(props.selected)
    let searchInput

    const updateData = props.noDebounce
      ? () => ctx.emit('update-data', property.value)
      : debounce(() => {
          ctx.emit('update-data', property.value)
        })

    const updateSelection = () => {
      props.options.forEach((option) => {
        const selected = property.value.find((opt) => opt.id === option.id)
        if (selected) {
          option.isSelected = true
        } else {
          option.isSelected = false
        }
      })

      if (!property.value.length)
        searchInput.classList.remove('select__search--hidden')
    }

    onMounted(() => {
      const select = vm.$refs.multipleProperties.$el
      searchInput = select.querySelector('.vs__search')
      searchInput.classList.add('select__search--hidden')
      updateSelection()
    })

    const selectAll = () => {
      property.value = props.options
      updateSelection()
      updateData()
    }

    const clearSelection = () => {
      property.value = []
      updateSelection()
      updateData()
    }

    const openSelect = () => {
      searchInput.classList.remove('select__search--hidden')
      searchInput.focus()
    }

    const closeSelect = () => {
      if (property.value.length)
        searchInput.classList.add('select__search--hidden')
    }

    return {
      property,
      updateData,
      updateSelection,
      selectAll,
      clearSelection,
      openSelect,
      closeSelect,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/libs/vue-select.scss';
@import '@/assets/scss/components/shared/multiple-properties';
</style>

<template>
  <!-- Vue Data Table -->
  <vue-good-table
    :mode="tableMode"
    :total-rows="data.totalRecords"
    :rows="isLoading ? [] : data.rows"
    :columns="data.columns"
    :style-class="isLoading ? 'loading-class' : ''"
    :search-options="{
      enabled: true,
      externalQuery: data.searchTerm
    }"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: false,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true
    }"
    :pagination-options="{
      enabled: isPaginationEnabled,
      perPage: data.perPage
    }"
    :sort-options="{
      enabled: isSortEnabled
    }"
    @on-sort-change="
      (params) => (data.totalRecords > 1 ? sortChanged(params) : {})
    "
    @on-search="handleSearch"
  >
    <template #table-row="data">
      <slot name="row-data" :data="data"></slot>
    </template>

    <template #table-column="data">
      <slot name="column-data" :data="data"></slot>
    </template>

    <!-- Empty state slot -->
    <div slot="emptystate" class="custom-table-empty-content">
      <TableEmptyState
        :is-loading="isLoading"
        :is-filtering="!!data.searchTerm.length || !!data.filters?.length"
        :type-of-table="typeOfTable"
        :click-handler="emptyStateAction"
        @clear-filters="clearFilters"
      />
    </div>

    <!-- pagination -->
    <template slot="pagination-bottom">
      <div class="d-flex table__pagination justify-content-between flex-wrap">
        <div class="table__pagination-left d-flex align-items-center mb-0">
          <span class="text-nowrap"> {{ $t('rowsPerPage') }}: </span>
          <b-form-select
            id="per-page-dropdown"
            v-model="data.perPage"
            :options="['5', '10', '50', '100']"
            class="mx-25 per-page__input"
            @input="changePerPage"
          />
        </div>
        <div class="table__pagination-right">
          <div class="specific-page__container">
            <span>{{ $t('page') }}</span>
            <b-form-input
              id="specific-page"
              v-model="data.page"
              :disabled="!data.totalRecords"
              :style="`--inputlength: ${pageInputLength}ch`"
              @input="changePage"
            />
            <span>{{ $t('message.of') }} {{ totalPages || 1 }}</span>
          </div>
          <div>
            <b-button
              id="previous-page"
              variant="outline-secondary"
              class="navigate__button"
              :disabled="cantNavigate('previous')"
              @click="navigate('previous')"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="16"
                class="pagination__icon"
              />
              <span
                class="pagination__text ml-25"
                :class="{
                  'pagination__text--disabled': cantNavigate('previous')
                }"
                >{{ $t('previous') }}</span
              >
            </b-button>
            <b-button
              id="next-page"
              variant="outline-secondary"
              class="navigate__button"
              :disabled="cantNavigate('next')"
              @click="navigate('next')"
            >
              <span
                class="pagination__text mr-25"
                :class="{
                  'pagination__text--disabled': cantNavigate('next')
                }"
                >{{ $t('next') }}</span
              >
              <feather-icon
                icon="ChevronRightIcon"
                size="16"
                class="pagination__icon"
              />
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
/* eslint-disable no-param-reassign */
import { capitalizeFirstLetter, debounce } from '@/utils'
import { BButton, BFormInput, BFormSelect } from 'bootstrap-vue'
import { computed, onBeforeMount } from 'vue'
import { VueGoodTable } from 'vue-good-table'
import TableEmptyState from './table-components/TableEmptyState.vue'

export default {
  components: {
    VueGoodTable,
    BFormSelect,
    BFormInput,
    BButton,
    TableEmptyState
  },
  props: {
    data: {
      type: [Array, Object]
    },
    isPaginationEnabled: {
      type: [Boolean]
    },
    isSortEnabled: {
      type: [Boolean],
      default: true
    },
    tableMode: {
      type: [String],
      default: 'remote'
    },
    typeOfTable: {
      type: [String]
    },
    isLoading: {
      type: [Boolean]
    },
    emptyStateAction: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['update-table', 'clear-filters'],
  setup(props, { emit }) {
    const realtimeData = props.data
    const localPerPage = localStorage.getItem(`${props.typeOfTable}PerPage`)

    const totalPages = computed(() => {
      const total = Math.floor(props.data.totalRecords / props.data.perPage)
      const remainder = props.data.totalRecords % props.data.perPage

      return remainder === 0 ? total : total + 1
    })

    const handlePageInput = () => {
      const { page } = props.data
      const total = totalPages.value
      if (page > total) return total || 1
      if (page >= 1 && page <= total) return +page
      return 1
    }

    const handleSearch = () => {
      props.data.page = 1
      emit('update-table')
    }

    const sortChanged = (params) => {
      props.data.orderField = capitalizeFirstLetter(params[0].field)
      props.data.orderType = params[0].type === 'asc' ? 1 : 2
      props.data.page = 1
      emit('update-table')
    }

    const changePerPage = () => {
      localStorage.setItem(`${props.typeOfTable}PerPage`, props.data.perPage)
      props.data.page = 1
      emit('update-table')
    }

    const changePage = debounce(() => {
      props.data.page = handlePageInput()
      emit('update-table')
    })

    const navigate = (dir) => {
      if (dir === 'next') props.data.page += 1
      else props.data.page -= 1
      emit('update-table')
    }

    const cantNavigate = (dir) => {
      if (dir === 'next')
        return (
          props.data.page === totalPages.value ||
          totalPages.value === 0 ||
          props.isLoading
        )

      return props.data.page === 1 || props.isLoading
    }

    const pageInputLength = computed(
      () => (props.data.page.toString().length || 1) + 2
    )

    const clearFilters = () => {
      props.data.searchTerm = ''

      if (props.data.filters?.length) emit('clear-filters')
    }

    onBeforeMount(() => {
      props.data.page = 1
      props.data.perPage = localPerPage || 10
      props.data.searchTerm = ''
      props.data.orderField = ''
      props.data.orderType = ''
    })

    return {
      realtimeData,
      handleSearch,
      sortChanged,
      totalPages,
      handlePageInput,
      changePage,
      changePerPage,
      navigate,
      cantNavigate,
      pageInputLength,
      clearFilters
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/table';

.custom-table-empty-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: calc(100vh - 304px);
  justify-content: center;
}
</style>

<template>
  <div class="table-list-img-container">
    <div v-for="(image, index) in activations.slice(0, 3)" :key="image.id">
      <div
        v-if="index < 2"
        :id="'activation-' + index + rowId"
        class="table-list-img-content"
      >
        <img :src="image.imageUrl" />
        <b-tooltip
          :target="'activation-' + index + rowId"
          triggers="hover"
          placement="bottom"
        >
          {{ image.name }}
        </b-tooltip>
      </div>
      <div v-if="index === 2" id="disabled-wrapper">
        <div class="table-list-img-content">
          <span :id="'activation-' + index + rowId">
            +{{ activations.length - 2 }}
          </span>
        </div>
        <b-tooltip
          :target="'activation-' + index + rowId"
          triggers="hover"
          placement="bottom"
        >
          <div v-for="img in activations.slice(2)" :key="img.id">
            <span>
              {{ img.name }}
            </span>
          </div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue'

export default {
  name: 'IntegrationColumn',
  components: {
    BTooltip,
  },
  props: {
    rowId: {
      type: Number,
    },
    activations: {
      type: Array,
      default: () => {},
    },
  },
}
</script>

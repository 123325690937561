<template>
  <section class="active-filters__wrapper">
    <div
      v-for="filter in activeFilters.slice(0, tagsToShow)"
      :id="`active-filter-${filter.column}`"
      :key="filter.column"
      class="active-filter__tag"
    >
      <span class="active-filter__label">{{ filter.label }}</span>
      <span
        v-if="getFilterValue(filter)?.operator"
        class="active-filter__operator"
      >{{ getFilterValue(filter).operator }}</span>
      <span class="active-filter__value">{{ getFilterValue(filter)?.value ?? getFilterValue(filter) }}</span>
      <b-button
        :id="`clear-${filter.column}`"
        size="sm"
        variant="outline-light"
        class="active-filter__clear"
        @click="(e) => removeFilter(e, filter)"
      >
        <b-spinner small variant="secondary" label="updating" class="d-none" />
        <GjIcon name="Close" size="18" />
      </b-button>
      <b-tooltip
        :target="`active-filter-${filter.column}`"
        :container="`active-filter-${filter.column}`"
        custom-class="active-filters__tooltip"
        :delay="{show: 500, hide: 0}"
        triggers="hover"
      >
        <span class="active-filters__tooltip-label">{{ filter.label }} </span>
        <span
          v-if="filter.type === FILTER_TYPES.STRING || filter.type === FILTER_TYPES.NUMBER"
          class="active-filters__tooltip-operator"
        >{{ getFilterValue(filter).operator }}</span>
        <span class="active-filters__tooltip-value">{{ getFilterValue(filter, false)?.value || getFilterValue(filter, false) }}</span>
      </b-tooltip>
    </div>
    <div
      v-if="activeFilters.length > tagsToShow"
      id="active-filters-stacked"
      class="active-filter__tag active-filter__tag--stacked"
    >
      <span class="active-filter__value">+{{ activeFilters.length - tagsToShow }}</span>
      <b-tooltip
        target="active-filters-stacked"
        container="active-filters-stacked"
        custom-class="active-filters__tooltip"
        triggers="hover"
      >
        <span class="active-filters__tooltip-label">{{ getStackedFilters() }}</span>
      </b-tooltip>
    </div>
  </section>
</template>

<script>
import {formatNumber} from '@/utils'
import GjIcon from '@gjirafatech/gjirafa-icons/Icon.vue'
import {BButton, BSpinner} from 'bootstrap-vue'
import {computed, getCurrentInstance} from 'vue'
import {
FILTER_TYPES,
stringOperators,
} from '../table-utils/filterListFunctions'

export default {
  name: 'ActiveFilters',
  emits: ['remove-filter'],
  components: {
    GjIcon,
    BButton,
    BSpinner,
  },
  props: {
    appliedFilters: {
      type: Array,
      default: () => [],
    },
    filters: {
      type: Array,
      default: () => [],
    },
    tagsToShow: {
      type: Number,
      default: 5,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy

    const activeFilters = computed(() =>
      props.filters.filter((f) =>
        props.appliedFilters.some((af) => af.column === f.column)
      )
    )

    const formatStringValue = (value) => {
      if (value.length > 20) return value.slice(0, 20) + '...'

      return value
    }

    const getAppliedFilter = (filter) =>
      props.appliedFilters.find((af) => af.column === filter.column)

    const getFilterValue = (filter, shorten = true) => {
      const appliedFilter = getAppliedFilter(filter)

      if (filter.type === FILTER_TYPES.NUMBER) {
        const { min, max } = appliedFilter.values

        if (min === null || max === null) {
          let value = min ?? max
          return {
            operator: min === null ? 'Less than' : 'More than',
            value: shorten ? formatNumber(value) : value.toLocaleString()
          }
        } else return shorten
            ? `${formatNumber(min)} to ${formatNumber(max)}`
            : `${min.toLocaleString()} to ${max.toLocaleString()}`
      } else if (filter.type === FILTER_TYPES.STRING) {
        const { searchTerm, operator } = appliedFilter.values
        return {
          operator: stringOperators.find((op) => op.value === operator).text,
          value: shorten ? formatStringValue(searchTerm) : searchTerm
        }
      } else {
        const filterValues = appliedFilter.values.map(
          (val) => filter.values.find(f => f.id === val).value
        )
        return shorten ? formatStringValue(filterValues.join(', ')) : filterValues.join(', ')
      }
    }

    const removeFilter = (e, filter) => {
      const filterTag = e.target
      filterTag.style = 'pointer-events: none;'
      const clearStates = Array.from(filterTag.children)
      clearStates.forEach(cs => cs.classList.toggle('d-none'))
      vm.$emit('remove-filter', filter)
    }

    const getStackedFilters = () => {
      const appliedFiltersLength = props.appliedFilters.length
      const stackedLength = appliedFiltersLength - props.tagsToShow
      const stackedFilters = []

      if (stackedLength > 0) {
        for (let i = 1; i <= stackedLength; i++)
          stackedFilters.push(activeFilters.value[appliedFiltersLength - i].label)
      }

      return stackedFilters.join(', ')
    }

    return { activeFilters, getFilterValue, removeFilter, getStackedFilters, FILTER_TYPES }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/components/table/table-components/filters/active-filters";
</style>

export const getOrganizationKey = () => localStorage.getItem('Organization')
export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1)
export const lowerCaseFirstLetter = (string) =>
  string.charAt(0).toLowerCase() + string.slice(1)

export const destructJSONObject = (object, key) => {
  const {
    data: {
      data: { [key]: data }
    }
  } = object

  return data
}

export const isEmail = (email) => {
  const regexPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
  return email === '' ? '' : regexPattern.test(email)
}

export const formatDate = (date) => {
  const getDate = new Date(date)
  return `${getDate.getDate()}/${
    getDate.getMonth() + 1
  }/${getDate.getFullYear()}`
}

export const isDateExpired = (expiryDate) => {
  const todaysDate = new Date()
  const expiringDate = new Date(expiryDate)

  return todaysDate > expiringDate
}

export const formatAnalyticsRequest = (propertyIds, dateFrom, dateTo) => {
  let queryParamString = ''
  if (propertyIds && propertyIds.length > 0) {
    const eURI = encodeURIComponent
    const query = Object.keys(propertyIds)
      .map((k) => `PropertyIds=${eURI(propertyIds[k])}`)
      .join('&')
    queryParamString = `DateFrom=${dateFrom}&DateTo=${dateTo}&${query}`
  } else {
    queryParamString = `DateFrom=${dateFrom}&DateTo=${dateTo}`
  }
  return queryParamString
}

export const formatRequestQuery = (
  searchTerm,
  page,
  perPage,
  orderField,
  orderType
) => {
  searchTerm = searchTerm.replaceAll('#', '%23')
  return `Search=${searchTerm}&Page=${page}&Count=${perPage}&OrderBy=${orderField}&SortingOrder=${orderType}`
}

export const formatAnalyticsDetailedRequest = (
  page,
  perPage,
  propertyIds,
  dateFrom,
  dateTo,
  orderField,
  orderType
) => {
  const sortParams =
    orderField === '' || orderType === ''
      ? ''
      : `&OrderBy=${orderField}&SortingOrder=${orderType}`
  let queryParamString = `Page=${page}&Count=${perPage}${sortParams}&DateFrom=${dateFrom}&DateTo=${dateTo}`
  if (propertyIds && propertyIds.length > 0) {
    const eURI = encodeURIComponent
    const query = Object.keys(propertyIds)
      .map((k) => `PropertyIds=${eURI(propertyIds[k])}`)
      .join('&')
    queryParamString += `&${query}`
  }
  return queryParamString
}

export const formatPropertiesQuery = (propertyIds) => {
  let query = ''
  if (propertyIds && propertyIds.length > 0) {
    const eURI = encodeURIComponent
    query = Object.keys(propertyIds)
      .map((k) => `PropertyIds=${eURI(propertyIds[k])}`)
      .join('&')
  }
  return query
}

export function debounce(func, timeout = 500) {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export function unFlattenObject(ob) {
  let toReturn = {}

  Object.keys(ob).forEach((path) => {
    const modifiedPath = path.replaceAll('[', '.').replaceAll(']', '')
    const keys = modifiedPath.split('.')
    keys.reduce((acc, value, index) => {
      value = lowerCaseFirstLetter(value)
      return (
        acc[value] ||
        (acc[value] = isNaN(Number(keys[index + 1]))
          ? keys.length - 1 === index
            ? ob[path]
            : {}
          : [])
      )
    }, toReturn)
  })

  return toReturn
}

export const appendErrors = (origObj, errObj) => {
  Object.keys(errObj).reduce((acc, key) => {
    if (
      Array.isArray(errObj[key]) &&
      errObj[key].every((item) => typeof item === 'string')
    ) {
      acc[key].errors = errObj[key]
    } else if (typeof errObj[key] === 'object' && errObj[key] !== null) {
      appendErrors(acc[key], errObj[key])
    }
    return acc
  }, origObj)

  return origObj
}

export const removeErrors = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (
      key === 'errors' &&
      obj[key].every((item) => typeof item === 'string')
    ) {
      obj[key] = []
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      removeErrors(obj[key])
    }
  })
}
export const createDownloadLink = (file, fileName) => {
  const name = fileName
  const url = window.URL.createObjectURL(new Blob([file]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name)
  document.body.appendChild(link)
  link.click()
}

export const scrollToElement = (query) => {
  const element = document.querySelector(query)
  if (!!element) {
    const yOffset = -75
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: y, behavior: 'smooth' })
  }
}

export const scrollToParentElement = (parent, element, difference) => {
  const container = document.getElementById(parent)
  const target = document.getElementById(element)

  const targetRect = target.getBoundingClientRect()
  const containerRect = container.getBoundingClientRect()

  const offset =
    targetRect.top - containerRect.top + container.scrollTop - difference
  container.scrollTo({ top: offset, behavior: 'smooth' })
}

export const isInteger = (value) => {
  const regex = /^[1-9]\d*$/

  return regex.test(value) && value < 2147483647
}

export const handleFormInput = (form = null, fields = []) => {
  if (form?.isPristine) form.isPristine = false
  fields.forEach((field) => {
    if (field?.errors.length > 0) field.errors = []
  })
}

const colors = [
  '#e0f5ff',
  '#c0e2f3',
  '#b2d9ee',
  '#a2d0e8',
  '#92c6e2',
  '#82bddc',
  '#71b3d6',
  '#61aad0',
  '#53a2cb',
  '#4298C5'
]
const percentages = [
  [0.005, 0.02, 0.075, 0.15, 0.4, 1.2, 5, 15, 30, 100],
  [0.2, 0.5, 1, 2, 6, 12, 20, 35, 50, 100],
  [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
]

export const getCountryColor = (value, maxValue, ratio) => {
  let values
  let color
  const percentage = (value / maxValue) * 100

  if (ratio < 0.35) values = percentages[2]
  else if (ratio < 0.75) values = percentages[1]
  else values = percentages[0]

  for (const val in values) {
    if (percentage < values[val]) {
      color = colors[val]
      break
    } else {
      color = colors[colors.length - 1]
    }
  }

  return color
}

export const confirmChanges = (modal) => {
  const response = modal.msgBoxConfirm('Changes done will not be saved', {
    title: 'Are you sure you want to leave this page?',
    size: 'md',
    okVariant: 'primary',
    okTitle: 'Leave',
    cancelTitle: 'Stay',
    cancelVariant: 'outline-secondary',
    modalClass: 'save-changes__modal',
    hideHeaderClose: false,
    centered: true,
    noCloseOnBackdrop: true
  })

  return response
}

export const handleLastRowDelete = (
  table,
  store,
  mutation = 'SET_TABLE_PAGE'
) => {
  const { totalRecords, page, perPage, rows } = table
  const lastPage = Math.ceil(totalRecords / perPage)
  if (page === lastPage && page !== 1 && rows.length === 1) {
    store.commit(mutation, page - 1)
  }
}

export const convertBytes = (size) => {
  const kbSize = size / 1024

  if (kbSize < 1024) return `${kbSize.toFixed(1)} KB`
  return `${(kbSize / 1024).toFixed(1)} MB`
}

export const countWords = (string) => {
  const arr = string.split(' ')

  return arr.filter((word) => word !== '').length
}

export const formatNumber = (value) => {
  if (value < 1e3) return value
  if (value >= 1e3 && value < 1e6) return `${+(value / 1e3).toFixed(1)}K`
  if (value >= 1e6 && value < 1e9) return `${+(value / 1e6).toFixed(1)}M`
  if (value >= 1e9 && value < 1e12) return `${+(value / 1e9).toFixed(1)}B`
  return value.toLocaleString()
}

export const formatSuggestedKeywordsParam = (propertyIds) => {
  let queryParamString = ''
  if (propertyIds && propertyIds.length > 0) {
    const eURI = encodeURIComponent
    queryParamString = Object.keys(propertyIds)
      .map((k) => `PropertyIds=${eURI(propertyIds[k])}`)
      .join('&')
  }
  return queryParamString
}

export const whitelabelConfig = {
  bisko: {
    title: 'bisko',
    logoUrl: `bisko-logo.svg`,
    welcomeText: 'Welcome to Bisko!',
    accountUrl: process.env.MY_ACCOUNT_URL,
    documentationUrl: 'https://bisko.gjirafa.tech/documentation'
  },
  integr8: {
    title: 'integr8',
    logoUrl: `integr8-logo.svg`,
    welcomeText: 'Welcome to Integr8 DMP!',
    accountUrl: process.env.MY_ACCOUNT_URL,
    documentationUrl:
      'https://documentation-dmp.sea.integr8.digital/documentation'
  }
}

const blockForLitePlan = [
  'users',
  'detailed',
  'visualizer',
  'real-time',
  'records',
  'connections',
  'exports',
  'queries',
  'topics',
  'lookalike-models',
  'lookalike-segments',
  'workspace',
  'dashboard'
]
export const isBlockedForLite = (item, organizationPlan) => {
  return (
    blockForLitePlan.some((val) => item.indexOf(val) !== -1) &&
    organizationPlan === 'Lite'
  )
}

const userInfoIState = {
  access_token: '',
  expires_at: 0,
  id_token: '',
  profile: {
    birthdate: '',
    gender: '',
    preferred_username: '',
    given_name: '',
    family_name: '',
    language_code: '',
    name: '',
    role: [],
    sub: '',
    picture: '',
    email: ''
  }
}

export const getUserData = () => {
  let userData = userInfoIState
  const _user = window.localStorage.getItem(
    `oidc.user:${process.env.APP_AUTHORITY}:${process.env.CLIENT_ID}`
  )
  if (_user) return JSON.parse(_user)
  else return userData
}

export { default as showToast } from './showToast'

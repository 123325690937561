var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "toast-container" }, [
    _c("img", {
      staticClass: "icon-container",
      attrs: {
        width: "24",
        height: "24",
        src:
          _vm.type === "success"
            ? require("@/assets/images/svgs/toast/toastsuccessicon.svg")
            : _vm.type === "error"
            ? require("@/assets/images/svgs/toast/toasterroricon.svg")
            : _vm.type === "info"
            ? require("@/assets/images/svgs/toast/toastinfoicon.svg")
            : "",
      },
      on: {
        click: function ($event) {
          return _vm.closeResponse()
        },
      },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "info-container" }, [
      _c("div", { staticClass: "title-container" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._v(" "),
      false
        ? _c("div", { staticClass: "date-container" }, [
            _vm._v(
              "\n      Date: " +
                _vm._s(_vm.date.getDate()) +
                "\n      " +
                _vm._s(_vm.date.toLocaleString("default", { month: "short" })) +
                ",\n      " +
                _vm._s(
                  _vm.date.toLocaleString("default", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })
                ) +
                "\n    "
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="upload-button">
    <b-button
      id="upload-keywords"
      v-b-modal.modal-upload-file
      variant="outline-secondary"
    >
      <GjIcon name="Upload" size="20" />
      {{ uploadButtonText }}
    </b-button>

    <!-- Modal for upload file -->
    <div class="user-roles-tables__duplicate-modal">
      <b-modal
        id="modal-upload-file"
        modal-class="upload-file"
        centered
        size="lg"
        :title="`Upload ${uploadType}`"
        no-enforce-focus
        no-close-on-backdrop
        @ok="!disableSaveButton ? onFileSave($event) : clearFileList()"
        @cancel="clearFileList"
        @close="clearFileList"
      >
        <div v-if="uploadDocumentation" class="documentation__info-text mb-1">
          <span
            >{{ uploadDocumentationText }}
            <a :href="uploadDocumentation" target="_blank">{{
              $t("Documentation").toLowerCase()
            }}</a
            >.</span
          >
        </div>

        <div
          id="drag__container"
          class="drag-and-drop__container"
          @dragover="onFileDragover($event)"
          @dragleave="onFileDragleave($event)"
          @drop.prevent="onFileDrop($event)"
        >
          <img src="@/assets/images/svgs/upload.svg" />
          <span class="drop-file__information"
            >{{ $t("audience.topics.dragAndDrop") }}
            <label class="browse-file" for="upload-file">{{
              $t("browse")
            }}</label>
            {{ $t("audience.topics.chooseAFile") }}.
          </span>
          <span class="uploaded-file__information">
            <p class="upload-information">
              {{ $t("audience.topics.uploadedFileSizeInformation") }}
              <b>{{ formattedFileSizeLimit }}</b
              >. {{ $t("audience.topics.supportedFormatsAre") }}
              <b>{{ fileTypes[0] }}</b> {{ $t("audience.or").toLowerCase() }}
              <b>{{ fileTypes[1] }}</b
              >.
            </p>
            <p v-if="uploadInfo" class="upload-information">
              {{ uploadInfo }}.
            </p>
          </span>
          <input
            id="upload-file"
            ref="file"
            type="file"
            :accept="allowedTypesExt"
            @change="onFileChange()"
          />
        </div>
        <div v-if="fileDetails.fileName" class="file-information">
          <div class="file-information__row file-information__row--background">
            <span
              class="file-information__cell file-information__cell--border"
              >{{ $t("Name") }}</span
            >
            <span
              class="file-information__cell file-information__cell--border size__cell"
              >{{ $t("size") }}</span
            >
            <span class="file-information__cell">{{ $t("type") }}</span>
          </div>
          <div class="file-information__row">
            <span
              id="file-name"
              class="file-information__cell file-information__filename"
            >
              <GjIcon name="File" size="22" />
              <span>{{ fileDetails.fileName }}</span>
            </span>
            <b-tooltip target="file-name" triggers="hover" placement="top">
              {{ fileDetails.fileName }}
            </b-tooltip>
            <span class="file-information__cell size__cell">
              {{ convertBytes(fileDetails.size) }}
              <span
                v-if="!isFileSizeValid"
                id="file-size__error"
                class="text--red"
              >
                <GjIcon name="InfoAlt" size="22" />
              </span>
              <b-tooltip
                v-if="!isFileSizeValid"
                target="file-size__error"
                triggers="hover"
                placement="top"
              >
                {{ $t("audience.topics.fileSizeErrorMessage") }}
                {{ formattedFileSizeLimit }}
              </b-tooltip>
            </span>
            <span class="file-information__cell">
              <span class="file-type__text">{{ fileDetails.type }}</span>
              <div class="file-type__icons">
                <span
                  v-if="!isFileTypeValid"
                  id="file-type__error"
                  class="text--red"
                >
                  <GjIcon name="InfoAlt" size="22" />
                </span>
                <b-tooltip
                  v-if="!isFileTypeValid"
                  target="file-type__error"
                  triggers="hover"
                  placement="top"
                >
                  {{ $t("audience.topics.fileFormatErrorMessage") }}
                </b-tooltip>
                <span class="remove-file__button" @click="removeFile()">
                  <GjIcon name="Close" size="22" />
                </span>
              </div>
            </span>
          </div>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <b-button size="sm" variant="secondary" @click="cancel()">
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            :class="{ 'upload-list-modal__loading': loading }"
            :disabled="disableSaveButton"
            @click="ok()"
          >
            <b-spinner
              v-if="loading"
              small
              class="upload-list-modal__spinner"
            ></b-spinner>
            {{ $t("Save") }}
          </b-button>
        </template>
      </b-modal>
    </div>

    <!-- Keywords list from file Modal -->
    <div class="user-roles-tables__duplicate-modal">
      <b-modal
        id="modal-keywords-from-file"
        modal-class="keywords-from-file"
        centered
        no-enforce-focus
        no-close-on-backdrop
        size="md"
        :title="`Enter ${uploadType} from the file \n ${fileDetails.fileName}`"
        @ok="addEntriesFromFileList()"
        @cancel="resetFileDetails"
        @close="resetFileDetails"
      >
        <slot
          name="fileContent"
          :fileContent="entriesFromFileList"
          :removeEntry="removeEntriesFromFile"
        ></slot>
        <div
          v-if="removedEntriesInfo && invalidEntriesCount > 0"
          class="documentation__info-text mb-50 mt-50 pl-1"
        >
          <span
            >{{ removedEntriesInfo }}
            <a :href="uploadDocumentation" target="_blank">{{
              $t("Documentation").toLowerCase()
            }}</a
            >.</span
          >
        </div>

        <template #modal-footer="{ ok, cancel }">
          <b-button size="sm" variant="secondary" @click="cancel()">
            {{ $t("Cancel") }}
          </b-button>
          <b-button size="sm" variant="primary" @click="ok()">
            {{ $t("Add") }}
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { BButton, VBModal, BModal, BTooltip, BSpinner } from "bootstrap-vue";
import { ref, reactive, computed, getCurrentInstance } from "vue";
import GjIcon from '@gjirafatech/gjirafa-icons/Icon.vue'
import { convertBytes } from "@/utils";

export default {
  name: "UploadListModal",
  emits: ["add-file-entries"],
  directives: {
    "b-modal": VBModal,
  },
  components: {
    BButton,
    BModal,
    GjIcon,
    BTooltip,
    BSpinner,
  },
  props: {
    uploadType: String,
    uploadButtonText: String,
    fileTypes: Array,
    fileSizeLimit: {
      type: Number,
      default: 2097152,
    },
    uploadFunction: Function,
    uploadParameters: {
      type: Array,
      default: () => [],
    },
    uploadInfo: String,
    uploadDocumentation: String,
    uploadDocumentationText: String,
    removedEntriesInfo: String,
    showReviewModal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const { organizationId } = vm.$route.params;
    const fileDetails = reactive({
      fileName: "",
      size: "",
      type: "",
    });

    const isFileTypeValid = ref(false);
    const isFileSizeValid = ref(false);
    const invalidEntriesCount = ref(0);
    const loading = ref(false);

    const entriesFromFileList = ref([]);

    const fileTypesConst = {
      csv: { type: "text/csv", ext: ".csv" },
      txt: { type: "text/plain", ext: ".txt" },
      json: { type: "application/json", ext: ".json" },
    };

    const allowedTypesExt = props.fileTypes
      .map((type) => fileTypesConst[type.toLowerCase()].ext)
      .join(", ");

    const fillFileDetails = () => {
      fileDetails.fileName = vm.$refs.file.files[0].name;
      fileDetails.size = vm.$refs.file.files[0].size;
      fileDetails.type = vm.$refs.file.files[0].type || "File";
    };

    const resetFileDetails = () => {
      fileDetails.fileName = "";
      fileDetails.size = "";
      fileDetails.type = "";
      isFileTypeValid.value = false;
      isFileSizeValid.value = false;
    };

    const clearFileList = () => {
      document.getElementById("upload-file").value = "";
      resetFileDetails();
    };

    const validateFile = ({ type, size }) => {
      isFileTypeValid.value = props.fileTypes.some(
        (fileType) => fileTypesConst[fileType.toLowerCase()].type === type
      );
      isFileSizeValid.value = size < props.fileSizeLimit;
    };

    const onFileChange = () => {
      validateFile(vm.$refs.file.files[0]);
      fillFileDetails();
    };

    const onFileDragover = (event) => {
      event.preventDefault();
      event.currentTarget.classList.add("drag--active");
    };

    const onFileDragleave = (event) => {
      event.currentTarget.classList.remove("drag--active");
    };

    const onFileDrop = (event) => {
      event.preventDefault();
      clearFileList();
      validateFile(event.dataTransfer.files[0]);
      vm.$refs.file.files = event.dataTransfer.files;
      fillFileDetails();
      event.currentTarget.classList.remove("drag--active");
    };

    const removeFile = () => {
      document.getElementById("upload-file").value = "";
      clearFileList();
    };

    const addEntriesFromFileList = () => {
      resetFileDetails();
      vm.$emit("add-file-entries", entriesFromFileList.value);
    };

    const onFileSave = async (modalEvent) => {
      modalEvent.preventDefault();
      loading.value = true;

      const fileData = new FormData();
      fileData.append("file", vm.$refs.file.files[0]);

      const response = await props.uploadFunction(
        organizationId,
        fileData,
        ...[props.uploadParameters]
      );
      loading.value = false;

      const { success, data } = response.data;

      if (success) {
        setTimeout(() => vm.$bvModal.hide("modal-upload-file"), 0);
        entriesFromFileList.value = data[props.uploadType];
        if (data.invalidEntriesCount)
          invalidEntriesCount.value = data.invalidEntriesCount;
        if (props.showReviewModal) vm.$bvModal.show("modal-keywords-from-file");
        else addEntriesFromFileList();
      }
    };

    const disableSaveButton = computed(
      () => !isFileTypeValid.value || !isFileSizeValid.value
    );

    const removeEntriesFromFile = (index) => {
      entriesFromFileList.value.splice(index, 1);
    };

    const formattedFileSizeLimit = computed(() => {
      const sizeInBytes = props.fileSizeLimit;

      if (sizeInBytes < 1e3) return `${sizeInBytes}B`;
      if (sizeInBytes >= 1e3 && sizeInBytes < 1e6)
        return `${+(sizeInBytes / 1e3).toFixed(0)}KB`;
      if (sizeInBytes >= 1e6 && sizeInBytes < 1e9)
        return `${+(sizeInBytes / 1e6).toFixed(0)}MB`;
      if (sizeInBytes >= 1e9 && sizeInBytes < 1e12)
        return `${+(sizeInBytes / 1e9).toFixed(0)}GB`;
      return sizeInBytes;
    });

    return {
      entriesFromFileList,
      fileDetails,
      addEntriesFromFileList,
      onFileChange,
      onFileDrop,
      onFileDragover,
      onFileDragleave,
      onFileSave,
      clearFileList,
      resetFileDetails,
      removeEntriesFromFile,
      removeFile,
      disableSaveButton,
      isFileSizeValid,
      isFileTypeValid,
      convertBytes,
      invalidEntriesCount,
      allowedTypesExt,
      formattedFileSizeLimit,
      loading,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/buttons";
@import "@/assets/scss/components/shared/upload-list-modal";
</style>

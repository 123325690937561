var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-wrapper" },
    [
      _c(
        "span",
        {
          staticClass: "d-inline-block",
          attrs: { id: "disabled-wrapper", tabindex: "0" },
        },
        [
          _c(
            "b-dropdown",
            {
              staticClass: "create__dropdown",
              class: {
                "create__dropdown-loading": _vm.isLoading || !_vm.canAdd,
              },
              attrs: {
                id: _vm.id,
                split: "",
                size: "sm",
                disabled: !_vm.canAdd || _vm.isLoading,
                variant: "primary",
                "no-caret": !_vm.canAdd || _vm.isLoading,
              },
              on: {
                click: function ($event) {
                  _vm.canAdd
                    ? _vm.$router.push({
                        name: _vm.routerPath,
                        params: { organizationId: _vm.organizationId },
                      })
                    : {}
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "button-content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "create__dropdown-content" },
                        [
                          _c("GjIcon", {
                            staticClass: "mr-50",
                            attrs: { name: "Plus", size: "18" },
                          }),
                          _vm._v(" "),
                          _c("span", [_vm._v(_vm._s(_vm.name))]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "create__dropdown-divider" }, [
                        _vm._v("|"),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  attrs: { id: "create-new" },
                  on: {
                    click: function ($event) {
                      _vm.canAdd ? _vm.createNewHandler() : {}
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "create__dropdown-option" },
                    [
                      _c("GjIcon", {
                        staticClass: "mr-50",
                        attrs: { name: "Plus", size: "18" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("createNew")))]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-dropdown-item",
                {
                  directives: [
                    {
                      name: "b-modal",
                      rawName: "v-b-modal.modal-lg-create-from-template",
                      modifiers: { "modal-lg-create-from-template": true },
                    },
                  ],
                  attrs: { id: "predefined-options" },
                },
                [
                  _c("div", { staticClass: "create__dropdown-option" }, [
                    _c("img", {
                      staticClass: "mr-50",
                      attrs: {
                        src: require("@/assets/images/svgs/newFile.svg"),
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.$t("selectFromTemplate")))]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.canAdd && !_vm.isLoading
        ? _c("b-tooltip", { attrs: { target: "disabled-wrapper" } }, [
            _vm._v("\n    " + _vm._s(_vm.$t("permissionMessage")) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container__integrations-content" },
    [
      _c("div", { staticClass: "container__integrations-content-header" }, [
        _c(
          "div",
          { staticClass: "container__integrations-content-img" },
          [
            _c("lazy-image-loading", {
              attrs: { src: _vm.activation.imageUrl },
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.viewMode
          ? _c("div", { staticClass: "custom-control custom-switch" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.activation.enabled,
                    expression: "activation.enabled",
                  },
                ],
                staticClass: "custom-control-input",
                attrs: { id: _vm.activation.id, type: "checkbox" },
                domProps: {
                  value: _vm.activation.enabled,
                  checked: Array.isArray(_vm.activation.enabled)
                    ? _vm._i(_vm.activation.enabled, _vm.activation.enabled) >
                      -1
                    : _vm.activation.enabled,
                },
                on: {
                  input: function ($event) {
                    return _vm.$emit("state-change")
                  },
                  change: function ($event) {
                    var $$a = _vm.activation.enabled,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = _vm.activation.enabled,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(_vm.activation, "enabled", $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.activation,
                            "enabled",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.activation, "enabled", $$c)
                    }
                  },
                },
              }),
              _vm._v(" "),
              _c("label", {
                staticClass: "custom-control-label",
                attrs: { for: _vm.activation.id },
              }),
            ])
          : _c("div", [
              _c("div", {
                class: `integration-status integration-status--${
                  _vm.activation.enabled ? "active" : "inactive"
                }`,
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.activation.enabled
                      ? _vm.$t("audience.active")
                      : _vm.$t("audience.inactive")
                  )
                ),
              ]),
            ]),
      ]),
      _vm._v(" "),
      _c("ReadMore", {
        attrs: {
          text: _vm.activation.name,
          classes: [
            "integration__title",
            { "integration__title--inactive": !_vm.activation.enabled },
          ],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
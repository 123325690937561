<template>
  <div class="lazy-load-component">
    <img
      v-if="!loading"
      :src="imgUrl"
      alt=""
    >

    <img
      ref="img"
      :src="imgUrl"
      alt="Image"
    >
  </div>
</template>

<script>
export default {
  /* eslint-disable global-require */
  props: {
    src: {
      type: String,
      required: true,
      default: '',
    },
    placeholder: {
      type: String,
    },
  },

  data() {
    return {
      imgUrl: require('@/assets/images/placeholder.png'),
      loading: false,
    }
  },

  mounted() {
    const { src, $el, $refs } = this

    const observer = new IntersectionObserver(([{ isIntersecting }]) => {
      const { img } = $refs

      img.onload = () => {
        delete img.onload

        this.loading = true
      }

      img.onerror = () => {
        delete img.onerror

        img.remove()
      }

      if (isIntersecting) {
        img.src = src
        observer.disconnect()
      }
    })

    observer.observe($el)

    this.$once('hook:beforeDestroy', () => {
      observer.disconnect()
    })
  },
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lazy-load-component" }, [
    !_vm.loading
      ? _c("img", { attrs: { src: _vm.imgUrl, alt: "" } })
      : _vm._e(),
    _vm._v(" "),
    _c("img", { ref: "img", attrs: { src: _vm.imgUrl, alt: "Image" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="icon-wrapper">
    <span
      id="disabled-wrapper"
      class="d-inline-block"
      tabindex="0"
    >
      <b-button
        :id="id"
        class="create__button ml-0"
        :disabled="!canAdd || isLoading"
        variant="gradient-primary"
        @click="canAdd ? clickHandler && clickHandler() : {}"
      >
        <GjIcon
          name="Plus"
          size="18"
        />
        <span class="align-middle">{{ name }}</span>
      </b-button>
    </span>
    <b-tooltip
      v-if="!canAdd && !isLoading"
      target="disabled-wrapper"
    >
      {{ $t('permissionMessage') }}
    </b-tooltip>
  </div>
</template>

<script>
import GjIcon from '@gjirafatech/gjirafa-icons/Icon.vue';
import {BButton, BTooltip} from 'bootstrap-vue';

export default {
  components: {
    BButton,
    BTooltip,
    GjIcon,
  },
  props: {
    name: {
      type: String,
    },
    canAdd: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    clickHandler: {
      type: Function,
    },
    id: {
      type: [String],
    },
  },
}
</script>

<style scoped lang="scss">
.create__button {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
</style>

import { capitalizeFirstLetter } from '@/utils'

const stringOperators = [
  { value: 1, text: 'Contains' },
  { value: 2, text: 'Starts with' },
  { value: 3, text: 'Ends with' },
]

const FILTER_TYPES = {
  KEY_VALUE_PAIR: 1,
  STRING: 2,
  NUMBER: 3,
  ENUM: 4,
}

const formatFilterQuery = (filters, appliedFilters) => {
  let query = ''

  appliedFilters.forEach((filter) => {
    const queryParam = capitalizeFirstLetter(filter.column)
    const filterType = filters.find((f) => f.column === filter.column).type

    if (filterType === FILTER_TYPES.NUMBER) {
      const { min, max } = filter.values
      const minValue =
        min === null ? '' : `&${queryParam}Min=${filter.values.min}`
      const maxValue =
        max === null ? '' : `&${queryParam}Max=${filter.values.max}`
      query += `${minValue}${maxValue}`
    } else if (filterType === FILTER_TYPES.STRING) {
      const operator = `${queryParam}Operator=${filter.values.operator}`
      const value = `${queryParam}Value=${filter.values.searchTerm}`
      query += `&${operator}&${value}`
    } else {
      filter.values.forEach((value) => {
        query += `&${queryParam}=${value}`
      })
    }
  })

  return query
}

const prepareTableFilters = (tableFilters, localFilters) => {
  let filters = []

  tableFilters.forEach((filter) => {
    const localFilter = localFilters?.find((f) => f.column === filter.column)

    if (
      (filter.type === FILTER_TYPES.KEY_VALUE_PAIR ||
        filter.type === FILTER_TYPES.ENUM) &&
      !filter.values.length
    ) {
      if (localFilter) {
        const filterIndex = localFilters.indexOf(localFilter)
        localFilters.splice(filterIndex, 1)
      }

      return
    }

    let filterObject = {
      type: filter.type,
      column: filter.column,
      label: filter.label,
      values: null,
    }

    if (filter.type === FILTER_TYPES.NUMBER) {
      filterObject.values = {
        min: localFilter?.values.min,
        max: localFilter?.values.max,
      }
    } else if (filter.type === FILTER_TYPES.STRING) {
      filterObject.values = {
        operator:
          localFilter?.values.operator ||
          stringOperators.find((operator) => operator.text === 'Contains')
            .value,
        searchTerm: localFilter?.values.searchTerm || '',
      }
    } else {
      filterObject.values = filter.values.map((filterValue) => {
        const { id, value, imageUrl } = filterValue
        let valueSelected = localFilter?.values.some((val) => val === id)

        return {
          id,
          value,
          selected: valueSelected,
          imageUrl,
        }
      })

      // check for local unexisting values
      if (localFilter) {
        localFilter.values.forEach((lval, valIndex) => {
          if (!filter.values.some((fval) => fval.id === lval))
            localFilter.values.splice(valIndex, 1)
        })

        if (!localFilter.values.length) {
          const filterIndex = localFilters.indexOf(localFilter)
          localFilters.splice(filterIndex, 1)
        }
      }
    }

    filters.push(filterObject)
  })

  return {
    filters,
    appliedFilters: localFilters,
  }
}

export { FILTER_TYPES, formatFilterQuery, prepareTableFilters, stringOperators }

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-list-img-container" },
    _vm._l(_vm.activations.slice(0, 3), function (image, index) {
      return _c("div", { key: image.id }, [
        index < 2
          ? _c(
              "div",
              {
                staticClass: "table-list-img-content",
                attrs: { id: "activation-" + index + _vm.rowId },
              },
              [
                _c("img", { attrs: { src: image.imageUrl } }),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: "activation-" + index + _vm.rowId,
                      triggers: "hover",
                      placement: "bottom",
                    },
                  },
                  [_vm._v("\n        " + _vm._s(image.name) + "\n      ")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        index === 2
          ? _c(
              "div",
              { attrs: { id: "disabled-wrapper" } },
              [
                _c("div", { staticClass: "table-list-img-content" }, [
                  _c(
                    "span",
                    { attrs: { id: "activation-" + index + _vm.rowId } },
                    [
                      _vm._v(
                        "\n          +" +
                          _vm._s(_vm.activations.length - 2) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  {
                    attrs: {
                      target: "activation-" + index + _vm.rowId,
                      triggers: "hover",
                      placement: "bottom",
                    },
                  },
                  _vm._l(_vm.activations.slice(2), function (img) {
                    return _c("div", { key: img.id }, [
                      _c("span", [
                        _vm._v(
                          "\n            " + _vm._s(img.name) + "\n          "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ],
              1
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
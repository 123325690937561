<template>
  <div class="container__integrations-content">
    <div class="container__integrations-content-header">
      <div class="container__integrations-content-img">
        <lazy-image-loading :src="activation.imageUrl" />
      </div>
      <div
        v-if="!viewMode"
        class="custom-control custom-switch"
      >
        <input
          :id="activation.id"
          v-model="activation.enabled"
          type="checkbox"
          class="custom-control-input"
          :value="activation.enabled"
          @input="$emit('state-change')"
        >
        <label
          class="custom-control-label"
          :for="activation.id"
        />
      </div>
      <div v-else>
        <div
          :class="`integration-status integration-status--${activation.enabled ? 'active' : 'inactive'}`"
        />
        <span>{{ activation.enabled ? $t('audience.active') : $t('audience.inactive') }}</span>
      </div>
    </div>
    <ReadMore
      :text="activation.name"
      :classes="[
        'integration__title',
        { 'integration__title--inactive': !activation.enabled },
      ]"
    />
  </div>
</template>

<script>
import LazyImageLoading from './LazyImageLoading.vue'
import ReadMore from './ReadMore.vue'

export default {
  name: 'IntegrationCard',
  components: {
    LazyImageLoading,
    ReadMore,
  },
  props: {
    activation: {
      type: [Object],
    },
    viewMode: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['state-change'],
}
</script>

<style lang="scss">
@import '@/assets/scss/components/shared/integration-card';
</style>

<template>
  <b-modal
    :id="id"
    modal-class="delete__modal"
    :ok-title="okTitle"
    :cancel-title="$t('Cancel')"
    ok-variant="danger"
    centered
    size="md"
    :title="title"
    no-enforce-focus
    @ok="$emit('delete')"
  >
    <span v-if="customContent">
      {{ customContent }} ?
    </span>
    <span v-else>
      <template v-if="itemName">
        {{ $t('confirmDeleteItem') }} <b>{{ itemName }}</b>
      </template>
      <template v-else>
        {{ $t('confirmDelete') }}
      </template>
      {{ type.toLowerCase() }} ?
    </span>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue';

export default {
  components: {
    BModal,
  },
  props: {
    id: {
      type: String,
      default: 'modal-small-delete',
    },
    title: {
      type: String,
    },
    type: {
      type: String,
    },
    itemName: {
      type: String,
      default: ''
    },
    okTitle: {
      type: String,
      default: 'Delete',
    },
    customContent: {
      type: [String],
      default: '',
    },
  },
  emits: ['delete'],
}
</script>

<style lang="scss">
@import "@/assets/scss/components/shared/delete-modal";
</style>

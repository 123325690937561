<template>
  <span class="information__sidebar">
    <span v-b-toggle="'disable-information' + code">
      <GjIcon name="Info" :size="size" />
    </span>
    <b-sidebar
      :id="'disable-information' + code"
      header-class="sidebar__header"
      title="Information"
      right
      backdrop
    >
      <div>
        <p class="sidebar__title">
          {{ title }}
        </p>
        <p class="sidebar__content">
          {{ content }}
        </p>
      </div>
    </b-sidebar>
  </span>
</template>

<script>
import { BSidebar, VBToggle } from 'bootstrap-vue'
import GjIcon from '@gjirafatech/gjirafa-icons/Icon.vue'

export default {
  components: {
    BSidebar,
    GjIcon,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    title: {
      type: String,
    },
    content: {
      type: String,
    },
    code: {
      type: String,
    },
    size: {
      type: String,
      default: '22',
    },
  },
}
</script>

<style lang="scss">
.information__sidebar {
  svg {
    color: #7483a1;
  }
  .b-sidebar {
    width: 280px;
    box-shadow: -2px 0px 16px rgba(5, 45, 97, 0.08);

    .sidebar__header {
      height: 56px;
      background: #f6f7f9;

      button {
        position: absolute;
        right: 5px;
      }

      strong {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #052d61;
      }
    }

    .b-sidebar-body {
      background: #ffffff;

      p {
        color: #052d61;
        margin: 0 15px;
      }

      .sidebar__title {
        font-weight: 600;
        font-size: 14px;
        margin-top: 24px;
      }

      .sidebar__content {
        font-size: 12px;
        margin-top: 16px;
      }
    }
  }

  .b-sidebar-outer {
    .b-sidebar-backdrop {
      opacity: 0;
    }
  }
}
</style>

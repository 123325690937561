var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes },
    [
      _c("v-select", {
        ref: "multipleProperties",
        staticClass: "form__select multiple-properties",
        class: `${
          _vm.property.length === _vm.options.length &&
          _vm.property.length > _vm.tagsToShow
            ? _vm.allSelectedClasses
            : ""
        } ${_vm.invalidInput ? "form__input--invalid" : ""}`,
        attrs: {
          id: _vm.id,
          options: _vm.options,
          label: _vm.label,
          multiple: "",
          disabled: _vm.isDisabled || _vm.isLoading,
          loading: _vm.isLoading,
          placeholder: _vm.isTopic
            ? _vm.$t("audience.topics.selectTopics")
            : _vm.$t("selectProperties"),
          "deselect-from-dropdown": true,
          "close-on-select": false,
          autoscroll: false,
        },
        on: {
          open: _vm.openSelect,
          close: _vm.closeSelect,
          input: function ($event) {
            _vm.updateData(), _vm.updateSelection()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selected-option",
            fn: function (option) {
              return [
                _vm.property.length === _vm.options.length &&
                _vm.property.length > _vm.tagsToShow
                  ? _c("span", [
                      _vm._v("\n        " + _vm._s(_vm.$t("All")) + "\n      "),
                    ])
                  : _vm.property.indexOf(
                      _vm.property.find((prop) => prop.id === option.id)
                    ) < _vm.tagsToShow
                  ? _c("span", [
                      _vm._v(
                        "\n        " + _vm._s(option[_vm.label]) + "\n      "
                      ),
                    ])
                  : option.id === _vm.property[_vm.tagsToShow].id
                  ? _c(
                      "span",
                      { attrs: { id: "properties-tooltip" } },
                      [
                        _c("span", [
                          _vm._v(
                            "(+" +
                              _vm._s(_vm.property.length - _vm.tagsToShow) +
                              " " +
                              _vm._s(_vm.$t("other"))
                          ),
                          _vm.property.length - _vm.tagsToShow !== 1
                            ? _c("span", [_vm._v("s")])
                            : _vm._e(),
                          _vm._v(")"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "properties-tooltip",
                              triggers: "hover",
                              placement: "right",
                            },
                          },
                          _vm._l(_vm.property, function (item, index) {
                            return _c("div", { key: index }, [
                              index >= _vm.tagsToShow
                                ? _c("span", [_vm._v(_vm._s(item[_vm.label]))])
                                : _vm._e(),
                            ])
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "option",
            fn: function (option) {
              return [
                _c(
                  "div",
                  { staticClass: "option__wrapper" },
                  [
                    _c("b-form-checkbox", {
                      staticClass: "option__checkbox",
                      model: {
                        value: option.isSelected,
                        callback: function ($$v) {
                          _vm.$set(option, "isSelected", $$v)
                        },
                        expression: "option.isSelected",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(option[_vm.label]))]),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "list-header",
            fn: function () {
              return [
                _c("div", { staticClass: "actions__container" }, [
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.selectAll()
                        },
                      },
                    },
                    [_vm._v("Select All")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "dot" }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.clearSelection()
                        },
                      },
                    },
                    [_vm._v("Clear")]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "spinner",
            fn: function ({ loading }) {
              return [
                loading
                  ? _c("LoadingBar", { attrs: { classList: "small mr-1" } })
                  : _vm._e(),
              ]
            },
          },
        ]),
        model: {
          value: _vm.property,
          callback: function ($$v) {
            _vm.property = $$v
          },
          expression: "property",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "button__container button__container--padding" },
    [
      _c(
        "b-button",
        {
          staticClass: "cancel__button",
          attrs: { id: _vm.cancelId },
          on: {
            click: function ($event) {
              return _vm.$emit("back")
            },
          },
        },
        [
          _vm.viewMode
            ? _c("span", { staticClass: "align-middle" }, [
                _vm._v(_vm._s(_vm.$t("Back"))),
              ])
            : _c("span", { staticClass: "align-middle" }, [
                _vm._v(_vm._s(_vm.$t("Cancel"))),
              ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: `${_vm.createId}-wrapper` } },
        [
          !_vm.viewMode
            ? _c(
                "b-button",
                {
                  staticClass: "create__button",
                  class: { "create__button--loading": _vm.submitted },
                  attrs: {
                    id: _vm.createId,
                    variant: "gradient-primary",
                    disabled: _vm.submitted || _vm.disableButton,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("create")
                    },
                  },
                },
                [
                  _vm.submitted
                    ? _c("LoadingBar", { attrs: { classList: "small mr-50" } })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.editMode
                    ? _c("span", { staticClass: "align-middle" }, [
                        _vm._v(_vm._s(_vm.$t("Save"))),
                      ])
                    : _c("span", { staticClass: "align-middle" }, [
                        _vm._v(_vm._s(_vm.$t("Create"))),
                      ]),
                  _vm._v(" "),
                  _vm._t("create-tooltip"),
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue'
import Toastification from '../components/shared/Toastification.vue'

export default function showToast(type, title) {
  Vue.$toast[type](
    {
      component: Toastification,
      props: {
        title: title,
        type: type,
      },
    },
    {
      position: 'bottom-left',
      timeout: 7029,
      closeOnClick: false,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: false,
      rtl: false,
    }
  )
}

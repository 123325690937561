<template>
  <b-form-group class="table__search">
    <div class="table__search__input-wrapper">
      <div>
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.64057 9.59517C7.80981 10.2259 6.77371 10.6002 5.6502 10.6002C2.91639 10.6002 0.700195 8.384 0.700195 5.6502C0.700195 2.91639 2.91639 0.700195 5.6502 0.700195C8.384 0.700195 10.6002 2.91639 10.6002 5.6502C10.6002 6.77371 10.2259 7.80981 9.59517 8.64057L12.8775 11.9229C13.1411 12.1865 13.1411 12.6139 12.8775 12.8775C12.6139 13.1411 12.1865 13.1411 11.9229 12.8775L8.64057 9.59517ZM9.2502 5.6502C9.2502 7.63842 7.63842 9.2502 5.6502 9.2502C3.66197 9.2502 2.0502 7.63842 2.0502 5.6502C2.0502 3.66197 3.66197 2.0502 5.6502 2.0502C7.63842 2.0502 9.2502 3.66197 9.2502 5.6502Z"
            fill="#A3B0C2"
          />
        </svg>
      </div>
      <b-form-input
        :id="id"
        v-model="data.searchTerm"
        :placeholder="$t('Search')"
        type="text"
        class="d-inline-block"
        :debounce="debounce"
      />
    </div>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormInput
  },
  props: {
    data: {
      type: [Array, Object]
    },
    id: {
      type: [String]
    },
    debounce: {
      type: [String],
      default: '500'
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c("div", [
        _c("div", { staticClass: "empty-content__icon" }, [
          _c("img", {
            attrs: {
              src: require(`@/assets/images/svgs/products/${_vm.tableIcon}.svg`),
              alt: _vm.tableIcon,
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "empty-content__info-text" }, [
          _vm.isFiltering
            ? _c("span", [_vm._v(_vm._s(_vm.$t("noDataFound")))])
            : _c("span", [
                _vm._v(_vm._s(_vm.emptyStateContent.noData) + "..."),
              ]),
        ]),
        _vm._v(" "),
        !_vm.isFiltering && _vm.clickHandler && _vm.clickHandler.canAdd
          ? _c("div", { staticClass: "empty-content__action" }, [
              _c("span", { on: { click: _vm.clickHandler.action } }, [
                _vm._v("+ " + _vm._s(_vm.emptyStateContent.addData)),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isFiltering
          ? _c("div", { staticClass: "empty-content__action" }, [
              _c(
                "span",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("clear-filters")
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("clearFilters")) + "\n    ")]
              ),
            ])
          : _vm._e(),
      ])
    : _c("LoadingBar")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
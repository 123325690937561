<template>
  <div class="loader__container">
    <span class="loader" :class="classList"></span>
  </div>
</template>

<script>
export default {
  name: 'LoadingBar',
  props: {
    classList: {
      type: [String],
      default: 'large'
    }
  },
};
</script>

<style scoped>
.loader__container {
  display: flex;
  justify-content: center;
}

.loader {
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  align-self: center;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.small {
  width: 16px;
  height: 16px;
  border: 2px solid #1d79f2;
  border-bottom-color: transparent;
}

.medium {
  width: 24px;
  height: 24px;
  border: 3px solid #1d79f2;
  border-bottom-color: transparent;
}

.large {
  width: 32px;
  height: 32px;
  border: 4px solid #1d79f2;
  border-bottom-color: transparent;
}

</style>
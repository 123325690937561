var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block-elem date-time__container" }, [
    _vm.data.row.insertDateTime
      ? _c("span", [
          _c("div", { staticClass: "block-elem--text" }, [
            _vm._v(_vm._s(_vm.$t("createdBy")) + " "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "segment__avatar" }, [
            _vm._v(_vm._s(_vm.data.row.createUser.charAt(0).toUpperCase())),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              class: {
                "text-capitalize": !_vm.isEmailValid(_vm.data.row.createUser),
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.data.row.createUser) + "\n    ")]
          ),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("at")) +
              " " +
              _vm._s(_vm.formatDate(_vm.data.row.insertDateTime)) +
              "\n  "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.data.row.updateDateTime
      ? _c("span", [
          _c(
            "div",
            {
              staticClass: "block-elem--text",
              class: { "update__user-text": !_vm.data.row.updateUser },
            },
            [
              _vm._v(
                _vm._s(
                  _vm.data.row.updateUser
                    ? _vm.$t("updatedBy")
                    : _vm.$t("updated")
                )
              ),
            ]
          ),
          _vm._v(" "),
          _vm.data.row.updateUser
            ? _c("span", [
                _c("span", { staticClass: "segment__avatar" }, [
                  _vm._v(
                    _vm._s(_vm.data.row.updateUser.charAt(0).toUpperCase())
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    class: {
                      "text-capitalize": !_vm.isEmailValid(
                        _vm.data.row.updateUser
                      ),
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.data.row.updateUser) +
                        "\n      "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(
            "\n    " +
              _vm._s(_vm.$t("at")) +
              " " +
              _vm._s(_vm.formatDate(_vm.data.row.updateDateTime)) +
              "\n  "
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div
    v-if="(prepareLinkItems || $route.meta.pageTitle) && !isFullscreen"
    class="breadcrumb__custom"
    :class="{ visible__menu: isMenuVisible }"
  >
    <b-row class="content-header">
      <!-- Content Left -->
      <b-col class="content-header-left mb-0" cols="12" md="12">
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title pr-1 mb-0">
              {{ $route.meta.pageTitle }}
            </h2>
            <div class="breadcrumb-wrapper test">
              <b-breadcrumb>
                <b-breadcrumb-item
                  v-for="item in prepareLinkItems"
                  :key="item.text"
                  :active="item.active"
                  :to="item.to"
                  :class="item.to ? 'clickable-link' : null"
                >
                  {{ item.text }}
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BBreadcrumb, BBreadcrumbItem, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'AppBreadCrumb',
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol
  },
  props: {
    module: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isMenuVisible: localStorage.getItem('navMenu') === 'expanded',
      isFullscreen: false
    }
  },
  computed: {
    prepareLinkItems() {
      return this.formatBreadcrumbLink(this.$route?.meta?.breadcrumb)
    }
  },
  beforeMount() {
    window.addEventListener('storage', (e) => {
      if (e.key === 'navMenu' && e.newValue) {
        this.isMenuVisible = e.newValue === 'expanded'
      }
    })
  },
  mounted() {
    document.addEventListener('fullscreenchange', this.handleFullscreenChange)
  },
  beforeUnmount() {
    document.removeEventListener(
      'fullscreenchange',
      this.handleFullscreenChange
    )
    window.removeEventListener('storage')
  },
  methods: {
    formatBreadcrumbLink(links) {
      return links?.reduce((acc, curr) => {
        if (curr.to)
          return [
            ...acc,
            {
              ...curr,
              to: `/bisko/${this.$route?.params?.organizationId}/${this.module}${curr.to}`
            }
          ]

        return [...acc, curr]
      }, [])
    },
    handleFullscreenChange() {
      if (document.fullscreenElement) {
        this.isFullscreen = true
      } else {
        this.isFullscreen = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/shared/app-breadcrumb';
</style>

<template>
  <div
    class="read-more__container"
    :class="classes"
  >
    <b-link
      v-if="to"
      v-bind="{ id }"
      :to="to"
    >{{ formattedText }}</b-link>
    <span
      v-else
      v-bind="{ id }"
      :class="{ json__text: parseJson }"
      @click="clickHandler"
    >{{ formattedText }}</span>
    <span
      v-if="text?.length > breakpoint"
      class="read-more__toggle"
      @click="toggleReadMore"
    >{{ readMoreActivated ? 'less' : 'more' }}</span>
  </div>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'

export default {
  props: {
    id: {
      type: [String],
    },
    text: {
      type: [String],
    },
    clickHandler: {
      type: [Function],
      default: () => {},
    },
    numberOfChars: {
      type: [Number],
      default: 50,
    },
    to: {
      type: [String, Object],
    },
    mode: {
      type: [String],
    },
    parseJson: {
      type: [Boolean],
      default: false,
    },
    classes: {
      type: [Array],
      default: () => [],
    },
  },
  setup(props) {
    const state = reactive({
      readMoreActivated: false,
      breakpoint: props?.mode === 'view' ? 200 : props.numberOfChars,
    })

    const parseJson = val => {
      try {
        const parsedValue = JSON.parse(val)
        return parsedValue
      } catch {
        return val
      }
    }

    const formattedText = computed(() => {
      const text = props?.text || null
      let finalText

      if (text?.length <= state.breakpoint)
        finalText = text
      else if (text?.length > state.breakpoint)
        finalText = state.readMoreActivated ? text : text.slice(0, state.breakpoint).concat('...')
      else
        return null
      
      return props.parseJson && state.readMoreActivated ? parseJson(text) : finalText
    })

    const toggleReadMore = () => {
      state.readMoreActivated = !state.readMoreActivated
    }

    return {
      ...toRefs(state),
      toggleReadMore,
      formattedText
    }
  },
}
</script>

<style lang="scss" scoped>
.read-more {
  &__container {
    word-break: break-word;

    a {
      color: inherit;
      text-decoration: none;
    }

    .json__text {
      white-space: pre-wrap;
    }
  }

  &__toggle {
    cursor: pointer;
    font-weight: bold;
    color: inherit;
    margin-left: 5px;
  
    &:hover {
      text-decoration: underline;
    }
  }
}

.processing-text-link span {
  opacity: 0.7;
}

.default-cursor span {
  cursor: default;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-button" },
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-upload-file",
              modifiers: { "modal-upload-file": true },
            },
          ],
          attrs: { id: "upload-keywords", variant: "outline-secondary" },
        },
        [
          _c("GjIcon", { attrs: { name: "Upload", size: "20" } }),
          _vm._v("\n    " + _vm._s(_vm.uploadButtonText) + "\n  "),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user-roles-tables__duplicate-modal" },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-upload-file",
                "modal-class": "upload-file",
                centered: "",
                size: "lg",
                title: `Upload ${_vm.uploadType}`,
                "no-enforce-focus": "",
                "no-close-on-backdrop": "",
              },
              on: {
                ok: function ($event) {
                  !_vm.disableSaveButton
                    ? _vm.onFileSave($event)
                    : _vm.clearFileList()
                },
                cancel: _vm.clearFileList,
                close: _vm.clearFileList,
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-footer",
                  fn: function ({ ok, cancel }) {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          class: { "upload-list-modal__loading": _vm.loading },
                          attrs: {
                            size: "sm",
                            variant: "primary",
                            disabled: _vm.disableSaveButton,
                          },
                          on: {
                            click: function ($event) {
                              return ok()
                            },
                          },
                        },
                        [
                          _vm.loading
                            ? _c("b-spinner", {
                                staticClass: "upload-list-modal__spinner",
                                attrs: { small: "" },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Save")) +
                              "\n        "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm.uploadDocumentation
                ? _c("div", { staticClass: "documentation__info-text mb-1" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.uploadDocumentationText) + "\n          "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href: _vm.uploadDocumentation,
                            target: "_blank",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
                      ),
                      _vm._v("."),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "drag-and-drop__container",
                  attrs: { id: "drag__container" },
                  on: {
                    dragover: function ($event) {
                      return _vm.onFileDragover($event)
                    },
                    dragleave: function ($event) {
                      return _vm.onFileDragleave($event)
                    },
                    drop: function ($event) {
                      $event.preventDefault()
                      return _vm.onFileDrop($event)
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/svgs/upload.svg") },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "drop-file__information" }, [
                    _vm._v(
                      _vm._s(_vm.$t("audience.topics.dragAndDrop")) +
                        "\n          "
                    ),
                    _c(
                      "label",
                      {
                        staticClass: "browse-file",
                        attrs: { for: "upload-file" },
                      },
                      [_vm._v(_vm._s(_vm.$t("browse")))]
                    ),
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("audience.topics.chooseAFile")) +
                        ".\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "uploaded-file__information" }, [
                    _c("p", { staticClass: "upload-information" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$t(
                              "audience.topics.uploadedFileSizeInformation"
                            )
                          ) +
                          "\n            "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.formattedFileSizeLimit))]),
                      _vm._v(
                        ". " +
                          _vm._s(
                            _vm.$t("audience.topics.supportedFormatsAre")
                          ) +
                          "\n            "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.fileTypes[0]))]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("audience.or").toLowerCase()) +
                          "\n            "
                      ),
                      _c("b", [_vm._v(_vm._s(_vm.fileTypes[1]))]),
                      _vm._v(".\n          "),
                    ]),
                    _vm._v(" "),
                    _vm.uploadInfo
                      ? _c("p", { staticClass: "upload-information" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.uploadInfo) +
                              ".\n          "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    ref: "file",
                    attrs: {
                      id: "upload-file",
                      type: "file",
                      accept: _vm.allowedTypesExt,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onFileChange()
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _vm.fileDetails.fileName
                ? _c("div", { staticClass: "file-information" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "file-information__row file-information__row--background",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "file-information__cell file-information__cell--border",
                          },
                          [_vm._v(_vm._s(_vm.$t("Name")))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass:
                              "file-information__cell file-information__cell--border size__cell",
                          },
                          [_vm._v(_vm._s(_vm.$t("size")))]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "file-information__cell" }, [
                          _vm._v(_vm._s(_vm.$t("type"))),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "file-information__row" },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "file-information__cell file-information__filename",
                            attrs: { id: "file-name" },
                          },
                          [
                            _c("GjIcon", {
                              attrs: { name: "File", size: "22" },
                            }),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(_vm._s(_vm.fileDetails.fileName)),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tooltip",
                          {
                            attrs: {
                              target: "file-name",
                              triggers: "hover",
                              placement: "top",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.fileDetails.fileName) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "file-information__cell size__cell" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.convertBytes(_vm.fileDetails.size)) +
                                "\n            "
                            ),
                            !_vm.isFileSizeValid
                              ? _c(
                                  "span",
                                  {
                                    staticClass: "text--red",
                                    attrs: { id: "file-size__error" },
                                  },
                                  [
                                    _c("GjIcon", {
                                      attrs: { name: "InfoAlt", size: "22" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isFileSizeValid
                              ? _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target: "file-size__error",
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t(
                                            "audience.topics.fileSizeErrorMessage"
                                          )
                                        ) +
                                        "\n              " +
                                        _vm._s(_vm.formattedFileSizeLimit) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "file-information__cell" }, [
                          _c("span", { staticClass: "file-type__text" }, [
                            _vm._v(_vm._s(_vm.fileDetails.type)),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "file-type__icons" },
                            [
                              !_vm.isFileTypeValid
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "text--red",
                                      attrs: { id: "file-type__error" },
                                    },
                                    [
                                      _c("GjIcon", {
                                        attrs: { name: "InfoAlt", size: "22" },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.isFileTypeValid
                                ? _c(
                                    "b-tooltip",
                                    {
                                      attrs: {
                                        target: "file-type__error",
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t(
                                              "audience.topics.fileFormatErrorMessage"
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass: "remove-file__button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile()
                                    },
                                  },
                                },
                                [
                                  _c("GjIcon", {
                                    attrs: { name: "Close", size: "22" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "user-roles-tables__duplicate-modal" },
        [
          _c(
            "b-modal",
            {
              attrs: {
                id: "modal-keywords-from-file",
                "modal-class": "keywords-from-file",
                centered: "",
                "no-enforce-focus": "",
                "no-close-on-backdrop": "",
                size: "md",
                title: `Enter ${_vm.uploadType} from the file \n ${_vm.fileDetails.fileName}`,
              },
              on: {
                ok: function ($event) {
                  return _vm.addEntriesFromFileList()
                },
                cancel: _vm.resetFileDetails,
                close: _vm.resetFileDetails,
              },
              scopedSlots: _vm._u([
                {
                  key: "modal-footer",
                  fn: function ({ ok, cancel }) {
                    return [
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "secondary" },
                          on: {
                            click: function ($event) {
                              return cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "primary" },
                          on: {
                            click: function ($event) {
                              return ok()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("Add")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _vm._t("fileContent", null, {
                fileContent: _vm.entriesFromFileList,
                removeEntry: _vm.removeEntriesFromFile,
              }),
              _vm._v(" "),
              _vm.removedEntriesInfo && _vm.invalidEntriesCount > 0
                ? _c(
                    "div",
                    {
                      staticClass: "documentation__info-text mb-50 mt-50 pl-1",
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.removedEntriesInfo) + "\n          "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.uploadDocumentation,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("Documentation").toLowerCase())
                            ),
                          ]
                        ),
                        _vm._v("."),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "information__sidebar" },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: "disable-information" + _vm.code,
              expression: "'disable-information' + code",
            },
          ],
        },
        [_c("GjIcon", { attrs: { name: "Info", size: _vm.size } })],
        1
      ),
      _vm._v(" "),
      _c(
        "b-sidebar",
        {
          attrs: {
            id: "disable-information" + _vm.code,
            "header-class": "sidebar__header",
            title: "Information",
            right: "",
            backdrop: "",
          },
        },
        [
          _c("div", [
            _c("p", { staticClass: "sidebar__title" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "sidebar__content" }, [
              _vm._v("\n        " + _vm._s(_vm.content) + "\n      "),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        "modal-class": "delete__modal",
        "ok-title": _vm.okTitle,
        "cancel-title": _vm.$t("Cancel"),
        "ok-variant": "danger",
        centered: "",
        size: "md",
        title: _vm.title,
        "no-enforce-focus": "",
      },
      on: {
        ok: function ($event) {
          return _vm.$emit("delete")
        },
      },
    },
    [
      _vm.customContent
        ? _c("span", [_vm._v("\n    " + _vm._s(_vm.customContent) + " ?\n  ")])
        : _c(
            "span",
            [
              _vm.itemName
                ? [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("confirmDeleteItem")) + " "
                    ),
                    _c("b", [_vm._v(_vm._s(_vm.itemName))]),
                  ]
                : [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("confirmDelete")) + "\n    "
                    ),
                  ],
              _vm._v("\n    " + _vm._s(_vm.type.toLowerCase()) + " ?\n  "),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-good-table",
    {
      attrs: {
        columns: _vm.data.columns,
        rows: _vm.isLoading ? [] : _vm.data.rows,
        "row-style-class":
          _vm.typeOfTable === "realtime" && _vm.realtimeUpdated
            ? _vm.rowStyleClassFn
            : "",
        "style-class": _vm.isLoading ? "loading-class" : "",
        "search-options": {
          enabled: true,
          externalQuery: _vm.data.searchTerm,
        },
        "select-options": {
          enabled: false,
          selectOnCheckboxOnly: false,
          selectionInfoClass: "custom-class",
          selectionText: "rows selected",
          clearSelectionText: "clear",
          disableSelectInfo: true,
          selectAllByGroup: true,
        },
        "pagination-options": {
          enabled: _vm.isPaginationEnabled,
          perPage: _vm.data.pageLength,
        },
      },
      scopedSlots: _vm._u(
        [
          {
            key: "table-row",
            fn: function (data) {
              return [_vm._t("row-data", null, { data: data })]
            },
          },
          {
            key: "pagination-bottom",
            fn: function (data) {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex user-roles__pagination justify-content-between flex-wrap",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "user-roles__pagination-left d-flex align-items-center mb-0 mt-1",
                      },
                      [
                        _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("message.pangelength")) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("b-form-select", {
                          staticClass: "mx-1",
                          attrs: { options: ["3", "5", "10"] },
                          on: {
                            input: (value) =>
                              data.perPageChanged({ currentPerPage: value }),
                          },
                          model: {
                            value: _vm.pageLength,
                            callback: function ($$v) {
                              _vm.pageLength = $$v
                            },
                            expression: "pageLength",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-nowrap" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("message.of")) +
                              " " +
                              _vm._s(data.total) +
                              "\n          " +
                              _vm._s(_vm.$t("message.pageText2")) +
                              "\n        "
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "user-roles__pagination-right" },
                      [
                        _c("b-pagination", {
                          staticClass: "mt-1 mb-0",
                          attrs: {
                            value: 1,
                            "total-rows": data.total,
                            "per-page": _vm.pageLength,
                            "first-number": "",
                            "last-number": "",
                            align: "right",
                            "prev-class": "prev-item",
                            "next-class": "next-item",
                          },
                          on: {
                            input: (value) =>
                              data.pageChanged({ currentPage: value }),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "prev-text",
                                fn: function () {
                                  return [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronLeftIcon",
                                        size: "18",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "next-text",
                                fn: function () {
                                  return [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "ChevronRightIcon",
                                        size: "18",
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        {
          class: `custom-table-empty-content ${_vm.typeOfTable}-empty-state`,
          attrs: { slot: "emptystate" },
          slot: "emptystate",
        },
        [
          _c("TableEmptyState", {
            attrs: {
              "is-loading": _vm.isLoading,
              "is-filtering": !!_vm.data.searchTerm.length,
              "type-of-table": _vm.typeOfTable,
              "click-handler": {},
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <!-- Vue Data Table -->
  <vue-good-table
    :columns="data.columns"
    :rows="isLoading ? [] : data.rows"
    :row-style-class="
      typeOfTable === 'realtime' && realtimeUpdated ? rowStyleClassFn : ''
    "
    :style-class="isLoading ? 'loading-class' : ''"
    :search-options="{
      enabled: true,
      externalQuery: data.searchTerm,
    }"
    :select-options="{
      enabled: false,
      selectOnCheckboxOnly: false,
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true,
      selectAllByGroup: true,
    }"
    :pagination-options="{
      enabled: isPaginationEnabled,
      perPage: data.pageLength,
    }"
  >
    <template #table-row="data">
      <slot name="row-data" :data="data"></slot>
    </template>

    <!-- Empty state slot -->
    <div
      slot="emptystate"
      :class="`custom-table-empty-content ${typeOfTable}-empty-state`"
    >
      <TableEmptyState
        :is-loading="isLoading"
        :is-filtering="!!data.searchTerm.length"
        :type-of-table="typeOfTable"
        :click-handler="{}"
      />
    </div>

    <!-- pagination -->
    <template slot="pagination-bottom" slot-scope="data">
      <div
        class="d-flex user-roles__pagination justify-content-between flex-wrap"
      >
        <div
          class="user-roles__pagination-left d-flex align-items-center mb-0 mt-1"
        >
          <span class="text-nowrap">
            {{ $t('message.pangelength') }}
          </span>
          <b-form-select
            v-model="pageLength"
            :options="['3', '5', '10']"
            class="mx-1"
            @input="(value) => data.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">
            {{ $t('message.of') }} {{ data.total }}
            {{ $t('message.pageText2') }}
          </span>
        </div>
        <div class="user-roles__pagination-right">
          <b-pagination
            :value="1"
            :total-rows="data.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="(value) => data.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<!-- eslint-disable no-param-reassign -->
<script>
import { BFormSelect, BPagination } from 'bootstrap-vue'
import { onUnmounted } from 'vue'
import { VueGoodTable } from 'vue-good-table'
import TableEmptyState from './table-components/TableEmptyState.vue'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormSelect,
    TableEmptyState,
  },
  props: {
    data: {
      type: [Array, Object],
    },
    isPaginationEnabled: {
      type: [Boolean],
      default: false,
    },
    typeOfTable: {
      type: [String],
    },
    isLoading: {
      type: [Boolean],
    },
    realtimeUpdated: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const pageLength = 10

    const rowStyleClassFn = (row) =>
      row.show
        ? `realtime__highlight--${row.growth === 1 ? 'green' : 'red'}`
        : ''

    onUnmounted(() => {
      props.data.searchTerm = ''
      props.data.rows = []
    })

    return {
      pageLength,
      rowStyleClassFn,
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/components/table';
.realtime__highlight {
  &--green {
    animation: greenhighlight 1.5s ease-out;
  }
  &--red {
    animation: redhighlight 1.5s ease-out;
  }
}

.realtime-empty-state {
  min-height: 264px;
}

.analytics-empty-state {
  min-height: 300px;
}

@keyframes redhighlight {
  0% {
    background-color: white;
  }
  40% {
    background-color: rgba(227, 72, 80, 0.5);
  }
  100% {
    background-color: white;
  }
}
@keyframes greenhighlight {
  0% {
    background-color: white;
  }
  40% {
    background-color: rgba(54, 179, 126, 0.5);
  }
  100% {
    background-color: white;
  }
}
</style>

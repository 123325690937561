<template>
  <div class="export-data" :class="{ exporting__data: exporting }">
    <b-dropdown
      :id="id"
      :disabled="exporting || isLoading || !hasData"
      variant="light"
      size="sm"
    >
      <template #button-content>
        <svg
          v-if="!exporting"
          width="12"
          height="14"
          viewBox="0 0 12 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.35773 0.549805L10.4785 4.67055V6.9998C10.4785 7.24833 10.277 7.44981 10.0285 7.44981C9.77995 7.44981 9.57847 7.24833 9.57847 6.9998V5.30695H7.67133C6.59438 5.30695 5.72133 4.4339 5.72133 3.35695V1.4498H2.0999C1.52 1.4498 1.0499 1.91991 1.0499 2.4998V11.4998C1.0499 12.0797 1.52 12.5498 2.0999 12.5498H10.0285C10.277 12.5498 10.4785 12.7513 10.4785 12.9998C10.4785 13.2483 10.277 13.4498 10.0285 13.4498H2.0999C1.02295 13.4498 0.149902 12.5768 0.149902 11.4998V2.4998C0.149902 1.42285 1.02295 0.549805 2.0999 0.549805H6.35773ZM6.62133 2.0862V3.35695C6.62133 3.93685 7.09143 4.40695 7.67133 4.40695H8.94208L6.62133 2.0862Z"
          />
          <path
            d="M4.86419 9.99981C4.86419 9.75128 5.06566 9.5498 5.31419 9.5498H10.4733L10.0826 8.9637C9.94475 8.75691 10.0006 8.47752 10.2074 8.33966C10.4142 8.2018 10.6936 8.25768 10.8314 8.46447L11.6847 9.74439C11.6875 9.74844 11.6903 9.75254 11.6929 9.75669L11.855 9.9998L11.6931 10.2427C11.6903 10.247 11.6875 10.2512 11.6846 10.2554L10.8314 11.5351C10.6936 11.7419 10.4142 11.7978 10.2074 11.6599C10.0006 11.5221 9.94475 11.2427 10.0826 11.0359L10.4733 10.4498H5.31419C5.06566 10.4498 4.86419 10.2483 4.86419 9.99981Z"
          />
        </svg>
        <div>
          <b-spinner v-if="exporting" small />
        </div>
      </template>
      <b-dropdown-item id="export-xlsx" @click="getFile(exportTypes.xlsx)">
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0.666504L6.5 8.12606L3.35355 4.97962C3.15829 4.78436 2.84171 4.78436 2.64645 4.97962C2.45118 5.17488 2.45118 5.49146 2.64645 5.68672L6.41074 9.45102C6.73618 9.77646 7.26382 9.77646 7.58926 9.45102L11.3536 5.68672C11.5488 5.49146 11.5488 5.17488 11.3536 4.97962C11.1583 4.78436 10.8417 4.78436 10.6464 4.97962L7.5 8.12606L7.5 0.666504C7.5 0.390361 7.27614 0.166504 7 0.166504C6.72386 0.166504 6.5 0.390361 6.5 0.666504Z"
            fill="#667C99"
          />
          <path
            d="M1.5 8.6665C1.5 8.39036 1.27614 8.1665 1 8.1665C0.723858 8.1665 0.5 8.39036 0.5 8.6665V9.99984C0.5 11.3805 1.61929 12.4998 3 12.4998H11C12.3807 12.4998 13.5 11.3805 13.5 9.99984V8.6665C13.5 8.39036 13.2761 8.1665 13 8.1665C12.7239 8.1665 12.5 8.39036 12.5 8.6665V9.99984C12.5 10.8283 11.8284 11.4998 11 11.4998H3C2.17157 11.4998 1.5 10.8283 1.5 9.99984V8.6665Z"
            fill="#667C99"
          />
        </svg>
        <span>{{ $t('ExportXlsx') }}</span>
      </b-dropdown-item>
      <b-dropdown-item id="export-csv" @click="getFile(exportTypes.csv)">
        <svg
          width="14"
          height="13"
          viewBox="0 0 14 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 0.666504L6.5 8.12606L3.35355 4.97962C3.15829 4.78436 2.84171 4.78436 2.64645 4.97962C2.45118 5.17488 2.45118 5.49146 2.64645 5.68672L6.41074 9.45102C6.73618 9.77646 7.26382 9.77646 7.58926 9.45102L11.3536 5.68672C11.5488 5.49146 11.5488 5.17488 11.3536 4.97962C11.1583 4.78436 10.8417 4.78436 10.6464 4.97962L7.5 8.12606L7.5 0.666504C7.5 0.390361 7.27614 0.166504 7 0.166504C6.72386 0.166504 6.5 0.390361 6.5 0.666504Z"
            fill="#667C99"
          />
          <path
            d="M1.5 8.6665C1.5 8.39036 1.27614 8.1665 1 8.1665C0.723858 8.1665 0.5 8.39036 0.5 8.6665V9.99984C0.5 11.3805 1.61929 12.4998 3 12.4998H11C12.3807 12.4998 13.5 11.3805 13.5 9.99984V8.6665C13.5 8.39036 13.2761 8.1665 13 8.1665C12.7239 8.1665 12.5 8.39036 12.5 8.6665V9.99984C12.5 10.8283 11.8284 11.4998 11 11.4998H3C2.17157 11.4998 1.5 10.8283 1.5 9.99984V8.6665Z"
            fill="#667C99"
          />
        </svg>
        <span>{{ $t('ExportCsv') }}</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BSpinner, BDropdown, BDropdownItem } from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BSpinner,
  },
  props: {
    exporting: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    getFile: {
      type: Function,
    },
    hasData: {
      type: Boolean,
    },
    id: {
      type: [String],
    },
  },
  setup() {
    const exportTypes = {
      xlsx: 1,
      csv: 2,
    }

    return {
      exportTypes,
    }
  },
}
</script>

<style lang="scss">
.export-data {
  button {
    background: #ffffff !important;
    color: #1d79f2 !important;
    max-height: 34px;
    width: 57px;
    padding: 10.55px 14px;
    padding-right: 12px;
    > svg {
      fill: #1d79f2;
    }
    &[disabled] > svg {
      fill: #a3b0c2;
    }

    &:hover {
      background: #f1f3fe !important;
    }
  }

  .dropdown-toggle.btn-sm::after {
    left: 4px;
    margin-left: 3px;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%231d79f2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>");
  }

  .btn-group
    > .btn:not([class*='btn-outline-']):not([class*='btn-flat-']):not(
      [class*='btn-gradient-']
    ):not([class*='btn-relief-']):last-of-type {
    border: 1px solid #1d79f2 !important;
  }

  ul.dropdown-menu {
    transform: translate3d(0px, 28px, 0px) !important;
    min-width: inherit;

    .dropdown-item {
      color: #052d61;
      padding: 10.55px 15px;
    }

    li a span {
      margin-left: 8px;
    }
  }

  // for disabled button
  button[disabled].dropdown-toggle.btn-sm::after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23A3B0C2' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'><polyline points='6 9 12 15 18 9'></polyline></svg>") !important;
  }

  .btn-group
    > .btn:not([class*='btn-outline-']):not([class*='btn-flat-']):not(
      [class*='btn-gradient-']
    ):not([class*='btn-relief-']):last-of-type:disabled {
    border: 1px solid #dadfe7 !important;
  }
}

.exporting__data {
  .dropdown-toggle.btn-sm::after {
    display: none;
  }
  button {
    padding: 10.55px 19px;
  }
}

.domains__wrapper--padding {
  .export-data {
    ul.dropdown-menu {
      transform: translate3d(-100px, 28px, 0px) !important;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: `${_vm.type}-tables__create-modal` },
    [
      _c(
        "b-modal",
        {
          ref: "modal",
          attrs: {
            id: `modal-lg-create-${
              _vm.type === "segment" ? `from-template` : _vm.type
            }`,
            "modal-class": "create__integration__modal",
            "ok-title": _vm.$t("Create"),
            "ok-disabled": !_vm.state.selected,
            "cancel-title": _vm.$t("Cancel"),
            "cancel-variant": "outline-secondary",
            centered: "",
            scrollable: _vm.type === "segment",
            size: "lg",
            title: _vm.modalTitle,
            "no-enforce-focus": "",
            "no-close-on-backdrop": "",
          },
          on: {
            ok: _vm.onCreate,
            close: _vm.closeModal,
            cancel: _vm.closeModal,
          },
        },
        [
          _vm.type !== "segment"
            ? _c("span", { staticClass: "integration__type" }, [
                _vm._v(_vm._s(_vm.modalText)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.documentationLink
            ? _c(
                "div",
                { staticClass: "documentation__info-text ml-1 mt-50" },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.documentationText) + "\n        "),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.documentationLink,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("Documentation").toLowerCase()))]
                    ),
                    _vm._v("."),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { class: `${_vm.type}-tables__create-modal__container` },
            _vm._l(_vm.selectOptions, function (option) {
              return _c(
                "div",
                {
                  key: option.id,
                  class: `${_vm.type}-tables__create-modal__container-box`,
                  on: {
                    click: function ($event) {
                      return _vm.chooseOption(option)
                    },
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.state.selected,
                        expression: "state.selected",
                      },
                    ],
                    attrs: {
                      id: option.name,
                      type: "radio",
                      name: "organization",
                    },
                    domProps: {
                      value: option.id,
                      checked: _vm._q(_vm.state.selected, option.id),
                    },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.state, "selected", option.id)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm.type === "segment"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "segment-table__create-modal__container-img",
                        },
                        [_c("img", { attrs: { src: option.icon } })]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "integration-table__create-modal__container-img",
                        },
                        [_c("img", { attrs: { src: option.imageUrl } })]
                      ),
                  _vm._v(" "),
                  _c("label", [_vm._v(_vm._s(option.name))]),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div :class="`${type}-tables__create-modal`">
    <b-modal
      :id="`modal-lg-create-${type === 'segment' ? `from-template` : type}`"
      ref="modal"
      modal-class="create__integration__modal"
      :ok-title="$t('Create')"
      :ok-disabled="!state.selected"
      :cancel-title="$t('Cancel')"
      cancel-variant="outline-secondary"
      centered
      :scrollable="type === 'segment'"
      size="lg"
      :title="modalTitle"
      no-enforce-focus
      no-close-on-backdrop
      @ok="onCreate"
      @close="closeModal"
      @cancel="closeModal"
    >
      <span v-if="type !== 'segment'" class="integration__type">{{
        modalText
      }}</span>
      <div v-if="documentationLink" class="documentation__info-text ml-1 mt-50">
        <span
          >{{ documentationText }}
          <a :href="documentationLink" target="_blank">{{
            $t("Documentation").toLowerCase()
          }}</a
          >.</span
        >
      </div>
      <div :class="`${type}-tables__create-modal__container`">
        <div
          v-for="option in selectOptions"
          :key="option.id"
          :class="`${type}-tables__create-modal__container-box`"
          @click="chooseOption(option)"
        >
          <input
            :id="option.name"
            v-model="state.selected"
            type="radio"
            name="organization"
            :value="option.id"
          />
          <div
            v-if="type === 'segment'"
            class="segment-table__create-modal__container-img"
          >
            <img :src="option.icon" />
          </div>
          <div v-else class="integration-table__create-modal__container-img">
            <img :src="option.imageUrl" />
          </div>
          <label>{{ option.name }}</label>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { reactive } from "vue";

export default {
  components: {
    BModal,
  },
  props: {
    type: {
      type: [String],
    },
    modalTitle: {
      type: [String],
    },
    modalText: {
      type: [String],
    },
    selectOptions: {
      type: [Array, Object],
    },
    documentationLink: {
      type: String,
      default: "",
    },
    documentationText: {
      type: String,
      default: "",
    },
  },
  emits: ["on-create"],
  setup(_, ctx) {
    const state = reactive({
      selected: "",
    });

    const chooseOption = (option) => {
      state.selected = option.id;
    };

    const closeModal = () => {
      state.selected = "";
    };

    const onCreate = () => {
      ctx.emit("on-create", state.selected);
    };

    return {
      state,
      chooseOption,
      closeModal,
      onCreate,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/buttons";
@import "@/assets/scss/components/shared/custom-modal";
</style>
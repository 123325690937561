import { t } from '@/libs/i18n/utils'

export const getGroup = (table) => {
  let group
  switch (table) {
    case 'organization-properties':
      group = 'properties'
      break
    case 'segments':
    case 'queries':
    case 'topics':
    case 'integrations':
      group = 'audience'
      break
    case 'records':
    case 'exports':
    case 'connections':
    case 'shared-connections':
      group = 'data'
      break
    case 'lookalike-models':
    case 'lookalike-segments':
      group = 'lookalike'
      break
    case 'dashboards':
    case 'analytics':
    case 'realtime':
      group = 'analytics'
      break
    case 'dashboard':
    case 'preview':
    case 'schema':
    case 'workspace':
      group = 'biskql'
      break
    case 'dashboard-share':
      group = 'dashboard-share'
      break
    default:
      group = 'users'
      break
  }

  return group
}

export const getIcon = (tableGroup) => {
  const icons = {
    audience: 'audience',
    data: 'data',
    lookalike: 'lookalike',
    users: 'organization',
    analytics: 'analytics',
    biskql: 'biskql',
    'dashboard-share': 'dashboard-share',
    properties: 'data'
  }
  return icons[tableGroup]
}

export const getEmptyStateContent = (table) => {
  const tables = {
    organization: {
      noData: t('accountManagement.userRoles.noOrganizationUsersAdded'),
      addData: t('accountManagement.userRoles.addNewOrganizationUser')
    },
    properties: {
      noData: t('accountManagement.userRoles.noPropertyUsersAdded'),
      addData: t('accountManagement.userRoles.addNewPropertyUser')
    },
    invitations: {
      noData: t('accountManagement.userRoles.noInvitationsAdded'),
      addData: ''
    },
    apikeys: {
      noData: t('accountManagement.userRoles.noAPIKeysAdded'),
      addData: t('accountManagement.userRoles.addNewAPIKey')
    },
    dashboards: {
      noData: t('visualizer.noDashboardsAdded'),
      addData: t('visualizer.createDashboard')
    },
    analytics: {
      noData: t('analytics.noData'),
      addData: ''
    },
    realtime: {
      noData: t('analytics.noData'),
      addData: ''
    },
    segments: {
      noData: t('audience.noSegmentsAdded'),
      addData: t('audience.addNewSegment')
    },
    queries: {
      noData: t('audience.noSegmentsAdded'),
      addData: t('audience.addNewQuery')
    },
    topics: {
      noData: t('audience.topics.noDataAdded'),
      addData: t('audience.topics.addNewData')
    },
    integrations: {
      noData: t('audience.noIntegrationsAdded'),
      addData: t('audience.addNewIntegration')
    },
    records: {
      noData: t('data.noRecordsAdded'),
      addData: t('data.addNewRecord')
    },
    exports: {
      noData: t('data.exports.noDataAdded'),
      addData: t('data.exports.addNewData')
    },
    connections: {
      noData: t('data.connections.noDataAdded'),
      addData: t('data.connections.addNewData')
    },
    'shared-connections': {
      noData: t('data.connections.noSharedDataAdded'),
      addData: ''
    },
    'lookalike-models': {
      noData: t('lookalike.noModelsAdded'),
      addData: t('lookalike.addNewModel')
    },
    'lookalike-segments': {
      noData: t('lookalike.noSegmentsAdded'),
      addData: ''
    },
    'dashboard-share': {
      noData: t('visualizer.noSharesAdded'),
      addData: t('visualizer.createShare')
    },
    'organization-properties': {
      noData: t('accountManagement.noPropertiesAddedYet'),
      addData: t('accountManagement.createNewProperty')
    },
    dashboard: {
      noData: t('noDataAdded'),
      addData: ''
    },
    preview: {
      noData: t('noDataAdded'),
      addData: ''
    },
    schema: {
      noData: t('noDataAdded'),
      addData: ''
    },
    workspace: {
      noData: t('noDataAdded'),
      addData: ''
    }
  }

  return tables[table]
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown-actions__menu" },
    [
      _c(
        "b-dropdown",
        {
          attrs: {
            id: _vm.id,
            size: "lg",
            variant: "link",
            offset: "-150",
            "toggle-class": "text-decoration-none",
            "no-caret": "",
          },
          scopedSlots: _vm._u([
            {
              key: "button-content",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "action-table-button" },
                    [
                      _c("feather-icon", {
                        staticClass: "text-body align-middle mr-25",
                        attrs: { icon: "MoreHorizontalIcon", size: "16" },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.items, function (item) {
            return [
              item.custom
                ? _vm._t("customOption", null, { item: item })
                : _c(
                    "b-dropdown-item",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal",
                          value:
                            !item.disabled && item?.modal ? item.modal : {},
                          expression:
                            "!item.disabled && item?.modal ? item.modal : {}",
                        },
                      ],
                      key: item.id,
                      class: item.disabled ? "disabled" : item.type,
                      attrs: {
                        id: item.id,
                        disabled: item.disabled,
                        to: !item.disabled && item?.to ? item.to : null,
                      },
                      on: {
                        click: function ($event) {
                          !item.disabled && item?.action ? item.action() : {}
                        },
                      },
                    },
                    [
                      _c("GjIcon", {
                        staticClass: "mr-50",
                        attrs: { name: item.icon, size: "18" },
                      }),
                      _vm._v("\n        " + _vm._s(item.text) + "\n      "),
                    ],
                    1
                  ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "icon-wrapper" },
    [
      _c(
        "span",
        {
          staticClass: "d-inline-block",
          attrs: { id: "disabled-wrapper", tabindex: "0" },
        },
        [
          _c(
            "b-button",
            {
              staticClass: "create__button ml-0",
              attrs: {
                id: _vm.id,
                disabled: !_vm.canAdd || _vm.isLoading,
                variant: "gradient-primary",
              },
              on: {
                click: function ($event) {
                  _vm.canAdd ? _vm.clickHandler && _vm.clickHandler() : {}
                },
              },
            },
            [
              _c("GjIcon", { attrs: { name: "Plus", size: "18" } }),
              _vm._v(" "),
              _c("span", { staticClass: "align-middle" }, [
                _vm._v(_vm._s(_vm.name)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.canAdd && !_vm.isLoading
        ? _c("b-tooltip", { attrs: { target: "disabled-wrapper" } }, [
            _vm._v("\n    " + _vm._s(_vm.$t("permissionMessage")) + "\n  "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
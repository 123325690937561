var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-list__container", class: _vm.classList.join(" ") },
    [
      _c(
        "div",
        { staticClass: "filter-list__wrapper" },
        [
          _c(
            "b-button",
            {
              staticClass: "filter-list__button",
              class: { editing: _vm.showMenu },
              attrs: {
                id: "filter-list",
                variant: "outline-primary",
                size: "sm",
                disabled:
                  _vm.loading || (!_vm.hasData && !_vm.appliedFilters.length),
              },
              on: { click: _vm.toggleMenu },
            },
            [
              _vm.updating && !_vm.showMenu.value
                ? _c(
                    "span",
                    [
                      _c("b-spinner", {
                        attrs: {
                          variant: "primary",
                          small: "",
                          label: "updating",
                        },
                      }),
                    ],
                    1
                  )
                : _c("GjIcon", { attrs: { name: "Filters", size: "20" } }),
              _vm._v(" "),
              _vm.appliedFilters.length
                ? _c("span", { staticClass: "filter-list__filter-count" }, [
                    _vm._v(_vm._s(_vm.appliedFilters.length)),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "div",
                {
                  staticClass: "filter-list__menu-wrapper",
                  class: { show: _vm.showMenu },
                  attrs: { tabindex: "-1" },
                },
                [
                  _c("div", { staticClass: "filter-list__menu-content" }, [
                    _c(
                      "ul",
                      { staticClass: "filter-list__menu-options" },
                      _vm._l(_vm.filters, function (filter, index) {
                        return _c(
                          "li",
                          {
                            key: filter.column,
                            staticClass: "filter-list__menu-item",
                            class: {
                              active: !!_vm.appliedFiltersCount(filter),
                              open: filter.column === _vm.filterMenu?.column,
                            },
                            attrs: {
                              id: `filter-${filter.column}`,
                              tabindex: index,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setFilterMenu(filter)
                              },
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  `${filter.label} ${
                                    !!_vm.appliedFiltersCount(filter)
                                      ? `(${_vm.appliedFiltersCount(filter)})`
                                      : ""
                                  }`
                                )
                              ),
                            ]),
                            _vm._v(" "),
                            _c("GjIcon", {
                              attrs: { name: "ArrowRight_fill", size: "18" },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticClass: "filter-list__menu-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "filter-list__clear-button",
                          attrs: {
                            tabindex: _vm.filters.length + 1,
                            disabled: _vm.clearFiltersDisabled,
                            size: "sm",
                          },
                          on: { click: _vm.clearFilters },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "clear-button__content" },
                            [
                              _c("GjIcon", {
                                attrs: { name: "Close", size: "18" },
                              }),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("clearFilters"))),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "filter-list__menu-wrapper submenu",
              class: { show: !!_vm.filterMenu },
              attrs: { id: "filter-list-submenu", tabindex: "-1" },
            },
            [
              _vm.filterMenu
                ? _c("div", { staticClass: "filter-list__menu-content" }, [
                    _vm.filterMenu.type !== _vm.FILTER_TYPES.ENUM
                      ? _c(
                          "div",
                          { staticClass: "filter-list__menu-header" },
                          [
                            _vm.filterMenu.type === _vm.FILTER_TYPES.NUMBER
                              ? _c(
                                  "div",
                                  {
                                    key: _vm.filterMenu.column,
                                    staticClass: "filter-list__range-container",
                                  },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "filter-list__small-input",
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        placeholder: "From",
                                        size: "sm",
                                        number: "",
                                        autofocus: "",
                                      },
                                      on: {
                                        blur: _vm.blurCheck,
                                        input: function ($event) {
                                          return _vm.applyNumberFilter(
                                            _vm.filterMenu
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.filterMenu.values.min,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterMenu.values,
                                            "min",
                                            $$v
                                          )
                                        },
                                        expression: "filterMenu.values.min",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("-")]),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "filter-list__small-input",
                                      attrs: {
                                        type: "number",
                                        min: "0",
                                        placeholder: "To",
                                        size: "sm",
                                        number: "",
                                      },
                                      on: {
                                        blur: _vm.blurCheck,
                                        input: function ($event) {
                                          return _vm.applyNumberFilter(
                                            _vm.filterMenu
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.filterMenu.values.max,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterMenu.values,
                                            "max",
                                            $$v
                                          )
                                        },
                                        expression: "filterMenu.values.max",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm.filterMenu.type === _vm.FILTER_TYPES.STRING
                              ? _c(
                                  "div",
                                  [
                                    _c("b-form-select", {
                                      staticClass:
                                        "filter-list__operator-select",
                                      attrs: {
                                        placeholder: "Select operator",
                                        options: _vm.stringOperators,
                                        size: "sm",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.applyStringFilter(
                                            _vm.filterMenu
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.filterMenu.values.operator,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterMenu.values,
                                            "operator",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterMenu.values.operator",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("b-form-input", {
                                      staticClass: "filter-list__search-bar",
                                      attrs: {
                                        placeholder: "Enter search term",
                                        size: "sm",
                                        autofocus: "",
                                      },
                                      on: {
                                        blur: _vm.blurCheck,
                                        input: function ($event) {
                                          return _vm.applyStringFilter(
                                            _vm.filterMenu
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.filterMenu.values.searchTerm,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.filterMenu.values,
                                            "searchTerm",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "filterMenu.values.searchTerm",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _c("b-form-input", {
                                  key: _vm.filterMenu.column ?? "search",
                                  staticClass: "filter-list__search-bar",
                                  attrs: {
                                    placeholder: "Search values",
                                    size: "sm",
                                    autofocus: "",
                                  },
                                  on: { blur: _vm.blurCheck },
                                  model: {
                                    value: _vm.searchTerm,
                                    callback: function ($$v) {
                                      _vm.searchTerm = $$v
                                    },
                                    expression: "searchTerm",
                                  },
                                }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.filterMenu.type === _vm.FILTER_TYPES.KEY_VALUE_PAIR ||
                    _vm.filterMenu.type === _vm.FILTER_TYPES.ENUM
                      ? _c(
                          "ul",
                          { staticClass: "filter-list__menu-options" },
                          [
                            _vm._l(
                              _vm.filteredMenuValues,
                              function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: item.id,
                                    staticClass:
                                      "filter-list__menu-item submenu-item",
                                    attrs: { tabindex: index },
                                    on: {
                                      click: function ($event) {
                                        return _vm.applyFilterValue(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-form-checkbox", {
                                      staticStyle: { "pointer-events": "none" },
                                      model: {
                                        value: item.selected,
                                        callback: function ($$v) {
                                          _vm.$set(item, "selected", $$v)
                                        },
                                        expression: "item.selected",
                                      },
                                    }),
                                    _vm._v(" "),
                                    item.imageUrl
                                      ? _c("img", {
                                          attrs: {
                                            src: item.imageUrl,
                                            alt: item.value,
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [_vm._v(_vm._s(item.value))]),
                                  ],
                                  1
                                )
                              }
                            ),
                            _vm._v(" "),
                            !_vm.filteredMenuValues.length
                              ? _c(
                                  "div",
                                  { staticClass: "filter-list__empty-state" },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("noMatches")) + "..."
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _c("div", { staticClass: "filter-list__menu-footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "filter-list__clear-button",
                          attrs: {
                            tabindex: _vm.filterMenu.length + 1,
                            disabled: !_vm.shouldApplyFilter(_vm.filterMenu),
                            size: "sm",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.clearFilter(_vm.filterMenu)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "clear-button__content" },
                            [
                              _c("GjIcon", {
                                attrs: { name: "Close", size: "18" },
                              }),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.$t("Clear")))]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="button__container button__container--padding">
    <b-button
      :id="cancelId"
      class="cancel__button"
      @click="$emit('back')"
    >
      <span
        v-if="viewMode"
        class="align-middle"
      >{{ $t('Back') }}</span>
      <span
        v-else
        class="align-middle"
      >{{ $t('Cancel') }}</span>
    </b-button>
    <div :id="`${createId}-wrapper`">
      <b-button
        v-if="!viewMode"
        :id="createId"
        variant="gradient-primary"
        class="create__button"
        :class="{ 'create__button--loading': submitted }"
        :disabled="submitted || disableButton"
        @click="$emit('create')"
      >
        <LoadingBar v-if='submitted' classList="small mr-50" />
        <span
          v-if="editMode"
          class="align-middle"
        >{{ $t('Save') }}</span>
        <span
          v-else
          class="align-middle"
        >{{ $t('Create') }}</span>
        <!-- Tooltip slot -->
        <slot name="create-tooltip" />
      </b-button>
    </div>
  </div>
</template>

<script>
import {BButton} from 'bootstrap-vue';
import LoadingBar from './LoadingBar.vue';

export default {
  components: {
    BButton,
    LoadingBar
  },
  props: {
    isLoading: {
      type: [Boolean],
    },
    submitted: {
      type: [Boolean],
    },
    viewMode: {
      type: [Boolean],
    },
    editMode: {
      type: [Boolean],
    },
    cancelId: {
      type: [String],
      default: '',
    },
    createId: {
      type: [String],
      default: '',
    },
    disableButton: {
      type: [Boolean],
    },
  },
  emits: ['back', 'create'],
  setup() {
  },
}
</script>

<style lang="scss">
.button__container {
  button {
    height: 34px;

    &.cancel__button {
      &:hover {
        background-color: #eeeeee !important;
      }
    }

    &.create__button {
      &:hover {
        background-color:#2c61b9;
      }
      .small {
        border: 2px solid #fff;
        border-bottom-color: transparent;
      }
    }
  }
}
</style>

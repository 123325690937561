var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vue-good-table",
    {
      attrs: {
        mode: _vm.tableMode,
        "total-rows": _vm.data.totalRecords,
        rows: _vm.isLoading ? [] : _vm.data.rows,
        columns: _vm.data.columns,
        "style-class": _vm.isLoading ? "loading-class" : "",
        "search-options": {
          enabled: true,
          externalQuery: _vm.data.searchTerm,
        },
        "select-options": {
          enabled: false,
          selectOnCheckboxOnly: false,
          selectionInfoClass: "custom-class",
          selectionText: "rows selected",
          clearSelectionText: "clear",
          disableSelectInfo: true,
          selectAllByGroup: true,
        },
        "pagination-options": {
          enabled: _vm.isPaginationEnabled,
          perPage: _vm.data.perPage,
        },
        "sort-options": {
          enabled: _vm.isSortEnabled,
        },
      },
      on: {
        "on-sort-change": (params) =>
          _vm.data.totalRecords > 1 ? _vm.sortChanged(params) : {},
        "on-search": _vm.handleSearch,
      },
      scopedSlots: _vm._u(
        [
          {
            key: "table-row",
            fn: function (data) {
              return [_vm._t("row-data", null, { data: data })]
            },
          },
          {
            key: "table-column",
            fn: function (data) {
              return [_vm._t("column-data", null, { data: data })]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "custom-table-empty-content",
          attrs: { slot: "emptystate" },
          slot: "emptystate",
        },
        [
          _c("TableEmptyState", {
            attrs: {
              "is-loading": _vm.isLoading,
              "is-filtering":
                !!_vm.data.searchTerm.length || !!_vm.data.filters?.length,
              "type-of-table": _vm.typeOfTable,
              "click-handler": _vm.emptyStateAction,
            },
            on: { "clear-filters": _vm.clearFilters },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "pagination-bottom" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex table__pagination justify-content-between flex-wrap",
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "table__pagination-left d-flex align-items-center mb-0",
              },
              [
                _c("span", { staticClass: "text-nowrap" }, [
                  _vm._v(" " + _vm._s(_vm.$t("rowsPerPage")) + ": "),
                ]),
                _vm._v(" "),
                _c("b-form-select", {
                  staticClass: "mx-25 per-page__input",
                  attrs: {
                    id: "per-page-dropdown",
                    options: ["5", "10", "50", "100"],
                  },
                  on: { input: _vm.changePerPage },
                  model: {
                    value: _vm.data.perPage,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "perPage", $$v)
                    },
                    expression: "data.perPage",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "table__pagination-right" }, [
              _c(
                "div",
                { staticClass: "specific-page__container" },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t("page")))]),
                  _vm._v(" "),
                  _c("b-form-input", {
                    style: `--inputlength: ${_vm.pageInputLength}ch`,
                    attrs: {
                      id: "specific-page",
                      disabled: !_vm.data.totalRecords,
                    },
                    on: { input: _vm.changePage },
                    model: {
                      value: _vm.data.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "page", $$v)
                      },
                      expression: "data.page",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("message.of")) +
                        " " +
                        _vm._s(_vm.totalPages || 1)
                    ),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "navigate__button",
                      attrs: {
                        id: "previous-page",
                        variant: "outline-secondary",
                        disabled: _vm.cantNavigate("previous"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.navigate("previous")
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "pagination__icon",
                        attrs: { icon: "ChevronLeftIcon", size: "16" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass: "pagination__text ml-25",
                          class: {
                            "pagination__text--disabled":
                              _vm.cantNavigate("previous"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("previous")))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass: "navigate__button",
                      attrs: {
                        id: "next-page",
                        variant: "outline-secondary",
                        disabled: _vm.cantNavigate("next"),
                      },
                      on: {
                        click: function ($event) {
                          return _vm.navigate("next")
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "pagination__text mr-25",
                          class: {
                            "pagination__text--disabled":
                              _vm.cantNavigate("next"),
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("next")))]
                      ),
                      _vm._v(" "),
                      _c("feather-icon", {
                        staticClass: "pagination__icon",
                        attrs: { icon: "ChevronRightIcon", size: "16" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="filter-list__container" :class="classList.join(' ')">
    <div class="filter-list__wrapper">
      <b-button
        id="filter-list"
        variant="outline-primary"
        size="sm"
        class="filter-list__button"
        :class="{ editing: showMenu }"
        :disabled="loading || (!hasData && !appliedFilters.length)"
        @click="toggleMenu"
      >
        <span v-if="updating && !showMenu.value">
          <b-spinner variant="primary" small label="updating" />
        </span>
        <GjIcon v-else name="Filters" size="20" />
        <span v-if="appliedFilters.length" class="filter-list__filter-count">{{
          appliedFilters.length
        }}</span>
      </b-button>
      <div
        v-if="!loading"
        class="filter-list__menu-wrapper"
        :class="{ show: showMenu }"
        tabindex="-1"
      >
        <div class="filter-list__menu-content">
          <ul class="filter-list__menu-options">
            <li
              v-for="(filter, index) in filters"
              :key="filter.column"
              :id="`filter-${filter.column}`"
              :tabindex="index"
              class="filter-list__menu-item"
              :class="{
                active: !!appliedFiltersCount(filter),
                open: filter.column === filterMenu?.column
              }"
              @click="setFilterMenu(filter)"
            >
              <span>{{
                `${filter.label} ${
                  !!appliedFiltersCount(filter)
                    ? `(${appliedFiltersCount(filter)})`
                    : ''
                }`
              }}</span>
              <GjIcon name="ArrowRight_fill" size="18" />
            </li>
          </ul>
          <hr />
          <div class="filter-list__menu-footer">
            <button
              class="filter-list__clear-button"
              :tabindex="filters.length + 1"
              :disabled="clearFiltersDisabled"
              size="sm"
              @click="clearFilters"
            >
              <div class="clear-button__content">
                <GjIcon name="Close" size="18" />
                <span>{{ $t('clearFilters') }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div
        id="filter-list-submenu"
        class="filter-list__menu-wrapper submenu"
        :class="{ show: !!filterMenu }"
        tabindex="-1"
      >
        <div v-if="filterMenu" class="filter-list__menu-content">
          <div
            v-if="filterMenu.type !== FILTER_TYPES.ENUM"
            class="filter-list__menu-header"
          >
            <div
              v-if="filterMenu.type === FILTER_TYPES.NUMBER"
              :key="filterMenu.column"
              class="filter-list__range-container"
            >
              <b-form-input
                v-model="filterMenu.values.min"
                class="filter-list__small-input"
                type="number"
                min="0"
                placeholder="From"
                size="sm"
                number
                autofocus
                @blur="blurCheck"
                @input="applyNumberFilter(filterMenu)"
              />
              <span>-</span>
              <b-form-input
                v-model="filterMenu.values.max"
                class="filter-list__small-input"
                type="number"
                min="0"
                placeholder="To"
                size="sm"
                number
                @blur="blurCheck"
                @input="applyNumberFilter(filterMenu)"
              />
            </div>
            <div v-else-if="filterMenu.type === FILTER_TYPES.STRING">
              <b-form-select
                v-model="filterMenu.values.operator"
                class="filter-list__operator-select"
                placeholder="Select operator"
                :options="stringOperators"
                size="sm"
                @input="applyStringFilter(filterMenu)"
              />
              <b-form-input
                v-model="filterMenu.values.searchTerm"
                class="filter-list__search-bar"
                placeholder="Enter search term"
                size="sm"
                autofocus
                @blur="blurCheck"
                @input="applyStringFilter(filterMenu)"
              />
            </div>
            <b-form-input
              v-else
              v-model="searchTerm"
              :key="filterMenu.column ?? 'search'"
              class="filter-list__search-bar"
              placeholder="Search values"
              size="sm"
              autofocus
              @blur="blurCheck"
            />
          </div>
          <ul
            v-if="
              filterMenu.type === FILTER_TYPES.KEY_VALUE_PAIR ||
              filterMenu.type === FILTER_TYPES.ENUM
            "
            class="filter-list__menu-options"
          >
            <li
              v-for="(item, index) in filteredMenuValues"
              :key="item.id"
              :tabindex="index"
              class="filter-list__menu-item submenu-item"
              @click="applyFilterValue(item)"
            >
              <b-form-checkbox
                v-model="item.selected"
                style="pointer-events: none"
              />
              <img
                v-if="item.imageUrl"
                :src="item.imageUrl"
                :alt="item.value"
              />
              <span>{{ item.value }}</span>
            </li>
            <div
              v-if="!filteredMenuValues.length"
              class="filter-list__empty-state"
            >
              <span>{{ $t('noMatches') }}...</span>
            </div>
          </ul>
          <hr />
          <div class="filter-list__menu-footer">
            <button
              class="filter-list__clear-button"
              :tabindex="filterMenu.length + 1"
              :disabled="!shouldApplyFilter(filterMenu)"
              size="sm"
              @click="clearFilter(filterMenu)"
            >
              <div class="clear-button__content">
                <GjIcon name="Close" size="18" />
                <span>{{ $t('Clear') }}</span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/utils'
import GjIcon from '@gjirafatech/gjirafa-icons/Icon.vue'
import {
  BButton,
  BFormCheckbox,
  BFormInput,
  BFormSelect,
  BSpinner
} from 'bootstrap-vue'
import { computed, getCurrentInstance, onMounted, ref } from 'vue'
import {
  FILTER_TYPES,
  stringOperators
} from '../table-utils/filterListFunctions'

export default {
  name: 'FilterList',
  emits: ['update-listing'],
  components: {
    BButton,
    BFormCheckbox,
    BFormInput,
    BFormSelect,
    BSpinner,
    GjIcon
  },
  props: {
    classList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    appliedFilters: {
      type: Array,
      default: () => []
    },
    loading: Boolean,
    hasData: Boolean
  },
  setup(props) {
    const vm = getCurrentInstance().proxy
    const showMenu = ref(false)
    const filterMenu = ref(null)
    const searchTerm = ref('')
    const updating = ref(false)
    let filterButton

    const resetFilterMenu = () => {
      if (!updating.value) {
        const appliedFilter = getAppliedFilter(filterMenu.value)
        const type = filterMenu.value.type
        const { NUMBER, STRING } = FILTER_TYPES

        if (
          (type === NUMBER || type === STRING) &&
          !shouldApplyFilter(filterMenu.value)
        ) {
          if (appliedFilter) {
            filterMenu.value.values = JSON.parse(
              JSON.stringify(appliedFilter.values)
            )
          } else {
            clearFilter(filterMenu.value, false)
          }
        }
      }

      searchTerm.value = ''
      filterMenu.value = null
    }

    const setFilterMenu = (filter) => {
      if (filterMenu.value) {
        resetFilterMenu()
      }

      const filterElem = document.getElementById(`filter-${filter.column}`)
      filterElem.classList.add('open')
      filterMenu.value = filter

      document.getElementById('filter-list-submenu').style.top = `${
        40 + filterElem.offsetTop
      }px`
    }

    const toggleMenu = (e) => {
      if (showMenu.value && filterMenu.value) {
        resetFilterMenu()
      }

      if (e?.target.id === 'filter-list' && showMenu.value) return

      showMenu.value = !showMenu.value
      if (e?.target.id === 'active-filters-more' && showMenu.value)
        filterButton.focus()
    }

    const blurCheck = (e) => {
      if (
        e.relatedTarget &&
        e.relatedTarget.className.startsWith('filter-list')
      ) {
        const ignoreElements = ['input']

        if (
          !ignoreElements.some(
            (val) => val === e.relatedTarget.tagName.toLowerCase()
          )
        )
          filterButton?.focus()
      } else if (
        e.relatedTarget &&
        e.relatedTarget.classList.contains('active-filters__item')
      ) {
        if (filterMenu.value) resetFilterMenu()
      } else {
        toggleMenu()
      }
    }

    onMounted(() => {
      filterButton = document.getElementById('filter-list')
      filterButton.addEventListener('blur', (e) => blurCheck(e))
    })

    const shouldApplyFilter = (filter) => {
      if (filter.type === FILTER_TYPES.NUMBER)
        return (
          typeof filter.values.min === 'number' ||
          typeof filter.values.max === 'number'
        )
      else if (filter.type === FILTER_TYPES.STRING)
        return !!filter.values.searchTerm
      else return filter.values.some((item) => item.selected)
    }

    const getAppliedFilter = (filter) =>
      props.appliedFilters.find((af) => af.column === filter.column)

    const appliedFiltersCount = (filter) => {
      const appliedFilter = getAppliedFilter(filter)

      if (
        filter.type === FILTER_TYPES.NUMBER ||
        filter.type === FILTER_TYPES.STRING
      )
        return appliedFilter ? 1 : 0
      else return appliedFilter ? appliedFilter.values.length : 0
    }

    const updateFilters = debounce(() => {
      let filters = []
      props.filters.forEach((filter) => {
        if (shouldApplyFilter(filter)) {
          if (
            filter.type === FILTER_TYPES.KEY_VALUE_PAIR ||
            filter.type === FILTER_TYPES.ENUM
          )
            filters.push({
              column: filter.column,
              values: filter.values
                .filter((item) => item.selected)
                .map((item) => item.id)
            })
          else {
            filters.push({
              column: filter.column,
              values: { ...filter.values }
            })
          }
        }
      })

      vm.$emit('update-listing', filters)
      updating.value = false
    }, 1000)

    const clearFilter = (filter, update = true) => {
      if (filter.type === FILTER_TYPES.NUMBER) {
        filter.values.min = null
        filter.values.max = null
      } else if (filter.type === FILTER_TYPES.STRING) {
        filter.values.operator = stringOperators.find(
          (operator) => operator.text === 'Contains'
        ).value
        filter.values.searchTerm = ''
      } else {
        filter.values.forEach((filterValue) => (filterValue.selected = false))
        searchTerm.value = ''
      }

      if (update) {
        updating.value = true
        updateFilters()
      }
    }

    const clearFilters = () => {
      updating.value = true
      props.filters.forEach((filter) => clearFilter(filter, false))
      updateFilters()
      if (filterMenu.value) resetFilterMenu()
    }

    const clearFiltersDisabled = computed(
      () => !props.filters.some((filter) => shouldApplyFilter(filter))
    )

    const filteredMenuValues = computed(
      () =>
        filterMenu.value?.values.filter(
          (item) =>
            item.selected ||
            item.value.toLowerCase().indexOf(searchTerm.value.toLowerCase()) !==
              -1
        ) ?? []
    )

    const applyFilterValue = (item) => {
      item.selected = !item.selected

      updating.value = true
      updateFilters()
    }

    const handleNumberValue = (val) => {
      if (typeof val !== 'number') return null
      else if (val < 0) return 0
      else if (val > 2147483647) return 2147483647
      else return Math.round(val)
    }

    const applyNumberFilter = (filter) => {
      const appliedFilter = getAppliedFilter(filter)
      const { min, max } = filter.values

      if (typeof min === 'number' || typeof max === 'number') {
        filter.values.min = handleNumberValue(min)
        filter.values.max = handleNumberValue(max)

        updating.value = true
        updateFilters()
      } else if (!min && !max && appliedFilter) {
        clearFilter(filter)
      }
    }

    const applyStringFilter = (filter) => {
      const appliedFilter = getAppliedFilter(filter)
      const { operator, searchTerm } = filter.values

      if (typeof operator === 'number' && !!searchTerm) {
        updating.value = true
        updateFilters()
      } else if (!searchTerm && appliedFilter) {
        updating.value = true
        updateFilters()
      }
    }

    return {
      showMenu,
      toggleMenu,
      setFilterMenu,
      filterMenu,
      blurCheck,
      appliedFiltersCount,
      shouldApplyFilter,
      clearFilter,
      clearFilters,
      searchTerm,
      filteredMenuValues,
      applyFilterValue,
      applyNumberFilter,
      applyStringFilter,
      stringOperators,
      FILTER_TYPES,
      clearFiltersDisabled,
      updating,
      getAppliedFilter
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/table/table-components/filters/filter-list';
</style>

<template>
  <div class="icon-wrapper">
    <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
      <b-dropdown
        :id="id"
        split
        class="create__dropdown"
        :class="{ 'create__dropdown-loading': isLoading || !canAdd }"
        size="sm"
        :disabled="!canAdd || isLoading"
        variant="primary"
        :no-caret="!canAdd || isLoading"
        @click="
          canAdd
            ? $router.push({
                name: routerPath,
                params: { organizationId },
              })
            : {}
        "
      >
        <template #button-content>
          <div class="create__dropdown-content">
            <GjIcon name="Plus" class="mr-50" size="18" />
            <span>{{ name }}</span>
          </div>
          <span class="create__dropdown-divider">|</span>
        </template>
        <b-dropdown-item
          id="create-new"
          @click="canAdd ? createNewHandler() : {}"
        >
          <div class="create__dropdown-option">
            <GjIcon name="Plus" class="mr-50" size="18" />
            <span>{{ $t("createNew") }}</span>
          </div>
        </b-dropdown-item>
        <b-dropdown-item
          id="predefined-options"
          v-b-modal.modal-lg-create-from-template
        >
          <div class="create__dropdown-option">
            <img src="@/assets/images/svgs/newFile.svg" class="mr-50" />
            <span>{{ $t("selectFromTemplate") }}</span>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </span>
    <b-tooltip v-if="!canAdd && !isLoading" target="disabled-wrapper">
      {{ $t("permissionMessage") }}
    </b-tooltip>
  </div>
</template>

<script>
import GjIcon from "@gjirafatech/gjirafa-icons/Icon.vue";
import { BDropdown, BDropdownItem, BTooltip } from "bootstrap-vue";
import { getCurrentInstance } from "vue";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BTooltip,
    GjIcon,
  },
  props: {
    name: {
      type: String,
    },
    canAdd: {
      type: Boolean,
    },
    isLoading: {
      type: Boolean,
    },
    createNewHandler: {
      type: [Function],
    },
    routerPath: {
      type: String,
      default: "segments-create",
    },
    id: {
      type: [String],
    },
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { organizationId } = vm.$route.params;

    return {
      organizationId,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/components/buttons";

.icon-wrapper {
  .btn-group {
    .btn.dropdown-toggle.btn-primary.btn-sm.dropdown-toggle-split {
      border-left-color: #1d79f2 !important;
    }
  }
}

.icon-wrapper {
  .dropdown.b-dropdown.btn-group.create__dropdown-loading {
    .btn.dropdown-toggle.btn-primary.btn-sm.dropdown-toggle-split {
      border-left-color: #6aa5f5 !important;
    }
  }
}

.create__dropdown {
  .btn:first-of-type {
    justify-content: flex-start;
    align-items: center;
  }
}

.create__dropdown-divider {
  color: #86b8f8;
  font-size: 20px;
  font-weight: 600;
  position: absolute;
  right: -4px;
  top: 2px;
}

.create__dropdown-content {
  color: #fff;

  & > span {
    vertical-align: middle;
  }
}

.create__dropdown {
  ul.dropdown-menu {
    transform: translateY(28px) !important;
  }
  ul.dropdown-menu li > a {
    padding-left: 13px;
  }
}

.create__dropdown-option span {
  vertical-align: middle;
}
</style>

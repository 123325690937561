var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "saveModal",
      attrs: {
        id: "save-changes-modal",
        "modal-class": "save-changes__modal",
        "ok-title": _vm.$t("yes"),
        "cancel-title": _vm.$t("no"),
        centered: "",
        size: "md",
        title: _vm.$t("saveChanges"),
        "no-enforce-focus": "",
      },
      on: {
        ok: function ($event) {
          return _vm.$emit("save")
        },
      },
    },
    [
      _c("span", [
        _vm._v(
          _vm._s(_vm.$t("confirmChangesMessage")) +
            " " +
            _vm._s(_vm.type.toLowerCase()) +
            " ?"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
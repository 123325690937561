<template>
  <b-modal
    id="save-changes-modal"
    ref="saveModal"
    modal-class="save-changes__modal"
    :ok-title="$t('yes')"
    :cancel-title="$t('no')"
    centered
    size="md"
    :title="$t('saveChanges')"
    no-enforce-focus
    @ok="$emit('save')"
  >
    <span>{{ $t('confirmChangesMessage') }} {{ type.toLowerCase() }} ?</span>
  </b-modal>
</template>

<script>
import {BModal} from 'bootstrap-vue';

export default {
  components: {
    BModal,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['save'],
}
</script>

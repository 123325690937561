<template>
  <div class="dropdown-actions__menu">
    <b-dropdown
      :id="id"
      size="lg"
      variant="link"
      offset="-150"
      toggle-class="text-decoration-none"
      no-caret
    >
      <template #button-content>
        <div class="action-table-button">
          <feather-icon
            icon="MoreHorizontalIcon"
            size="16"
            class="text-body align-middle mr-25"
          />
        </div>
      </template>

      <template v-for="item in items">
        <slot v-if="item.custom" name="customOption" :item="item"> </slot>
        <b-dropdown-item
          v-else
          :id="item.id"
          :key="item.id"
          :disabled="item.disabled"
          :to="!item.disabled && item?.to ? item.to : null"
          :class="item.disabled ? 'disabled' : item.type"
          @click="!item.disabled && item?.action ? item.action() : {}"
          v-b-modal="!item.disabled && item?.modal ? item.modal : {}"
        >
          <GjIcon :name="item.icon" class="mr-50" size="18" />
          {{ item.text }}
        </b-dropdown-item>
      </template>
    </b-dropdown>
  </div>
</template>

<script>
import GjIcon from '@gjirafatech/gjirafa-icons/IconCdn.vue'

export default {
  name: 'DropdownActions',
  components: {
    GjIcon
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    id: {
      type: String,
      default: 'dropdown-actions'
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/components/shared/dropdown-actions';
</style>

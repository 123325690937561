import Vue from 'vue'
import Toast from 'vue-toastification'
import { FeatherIcon } from './components'

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 5,
  newestOnTop: true,
})

Vue.component(FeatherIcon.name, FeatherIcon)

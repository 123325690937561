var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "active-filters__wrapper" },
    [
      _vm._l(_vm.activeFilters.slice(0, _vm.tagsToShow), function (filter) {
        return _c(
          "div",
          {
            key: filter.column,
            staticClass: "active-filter__tag",
            attrs: { id: `active-filter-${filter.column}` },
          },
          [
            _c("span", { staticClass: "active-filter__label" }, [
              _vm._v(_vm._s(filter.label)),
            ]),
            _vm._v(" "),
            _vm.getFilterValue(filter)?.operator
              ? _c("span", { staticClass: "active-filter__operator" }, [
                  _vm._v(_vm._s(_vm.getFilterValue(filter).operator)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("span", { staticClass: "active-filter__value" }, [
              _vm._v(
                _vm._s(
                  _vm.getFilterValue(filter)?.value ??
                    _vm.getFilterValue(filter)
                )
              ),
            ]),
            _vm._v(" "),
            _c(
              "b-button",
              {
                staticClass: "active-filter__clear",
                attrs: {
                  id: `clear-${filter.column}`,
                  size: "sm",
                  variant: "outline-light",
                },
                on: { click: (e) => _vm.removeFilter(e, filter) },
              },
              [
                _c("b-spinner", {
                  staticClass: "d-none",
                  attrs: { small: "", variant: "secondary", label: "updating" },
                }),
                _vm._v(" "),
                _c("GjIcon", { attrs: { name: "Close", size: "18" } }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-tooltip",
              {
                attrs: {
                  target: `active-filter-${filter.column}`,
                  container: `active-filter-${filter.column}`,
                  "custom-class": "active-filters__tooltip",
                  delay: { show: 500, hide: 0 },
                  triggers: "hover",
                },
              },
              [
                _c("span", { staticClass: "active-filters__tooltip-label" }, [
                  _vm._v(_vm._s(filter.label) + " "),
                ]),
                _vm._v(" "),
                filter.type === _vm.FILTER_TYPES.STRING ||
                filter.type === _vm.FILTER_TYPES.NUMBER
                  ? _c(
                      "span",
                      { staticClass: "active-filters__tooltip-operator" },
                      [_vm._v(_vm._s(_vm.getFilterValue(filter).operator))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "active-filters__tooltip-value" }, [
                  _vm._v(
                    _vm._s(
                      _vm.getFilterValue(filter, false)?.value ||
                        _vm.getFilterValue(filter, false)
                    )
                  ),
                ]),
              ]
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.activeFilters.length > _vm.tagsToShow
        ? _c(
            "div",
            {
              staticClass: "active-filter__tag active-filter__tag--stacked",
              attrs: { id: "active-filters-stacked" },
            },
            [
              _c("span", { staticClass: "active-filter__value" }, [
                _vm._v("+" + _vm._s(_vm.activeFilters.length - _vm.tagsToShow)),
              ]),
              _vm._v(" "),
              _c(
                "b-tooltip",
                {
                  attrs: {
                    target: "active-filters-stacked",
                    container: "active-filters-stacked",
                    "custom-class": "active-filters__tooltip",
                    triggers: "hover",
                  },
                },
                [
                  _c("span", { staticClass: "active-filters__tooltip-label" }, [
                    _vm._v(_vm._s(_vm.getStackedFilters())),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "resizable-column" }, [
    _c("span", [_vm._v(_vm._s(_vm.content))]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "resizer",
        on: {
          mousedown: function ($event) {
            return _vm.mouseDownHandler($event)
          },
          mouseup: function ($event) {
            return _vm.mouseUpHandler($event)
          },
        },
      },
      [_c("div", { staticClass: "divider" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
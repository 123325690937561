<template>
  <div class="resizable-column">
    <span>{{ content }}</span>
    <div
      class="resizer"
      @mousedown="mouseDownHandler($event)"
      @mouseup="mouseUpHandler($event)"
    >
      <div class="divider"></div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue';

export default {
  name: 'ResizableColumn',
  props: {
    content: {
      type: [String],
      default: '',
    },
  },
  setup() {
    const columnStartingPosition = ref(0);
    const columnWidth = ref(0);
    const columnToResize = ref('');
    const columnCellIndex = ref(null);
    const textWrapped = ref(false);

    const changeCellStyle = (style) => {
      const table = document.querySelector('table');
      const columns = table.querySelectorAll('td');
      columns.forEach((col) => {
        if (col.cellIndex === columnCellIndex.value) {
          if (style === 'add-wrap') col.classList.add('text--wrap');
          else col.classList.remove('text--wrap');
        }
      });
    };

    const mouseDownHandler = (e) => {
      columnCellIndex.value = e.target.offsetParent.cellIndex;
      columnToResize.value = e.target.offsetParent;
      columnStartingPosition.value = e.clientX;
      columnWidth.value = columnToResize.value.offsetWidth;
      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
      const difference = e.clientX - columnStartingPosition.value;
      columnToResize.value.style.minWidth = `${
        columnWidth.value + difference
      }px`;
      if (columnToResize.value.offsetWidth > 400 && !textWrapped.value) {
        changeCellStyle('add-wrap');
        textWrapped.value = true;
      } else if (columnToResize.value.offsetWidth < 400 && textWrapped.value) {
        changeCellStyle('remove-wrap');
        textWrapped.value = false;
      }
    };

    const mouseUpHandler = () => {
      document.removeEventListener('mousemove', mouseMoveHandler);
      document.removeEventListener('mouseup', mouseUpHandler);
      columnToResize.value = '';
    };

    return {
      mouseDownHandler,
      mouseUpHandler,
    };
  },
};
</script>

<style lang="scss">
.resizer {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: col-resize;
  user-select: none;
  width: 8px;
  height: 100%;
  z-index: 1;
}

.divider {
  border-left: 1px solid #edeff3;
  height: 100%;
  width: 0px;
  margin: 0 auto;
  pointer-events: none;
}

.text--wrap {
  white-space: break-spaces !important;
  overflow-wrap: anywhere !important;
}

.resizable-column {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
}
</style>

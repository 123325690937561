<template>
  <div class="block-elem date-time__container">
    <span v-if="data.row.insertDateTime">
      <div class="block-elem--text">{{ $t('createdBy') }} </div>
      <span class="segment__avatar">{{ data.row.createUser.charAt(0).toUpperCase() }}</span>
      <span :class="{ 'text-capitalize': !isEmailValid(data.row.createUser) }">
        {{ data.row.createUser }}
      </span>
      {{ $t('at') }} {{ formatDate(data.row.insertDateTime) }}
    </span>
    <span v-if="data.row.updateDateTime">
      <div class="block-elem--text" :class="{ 'update__user-text': !data.row.updateUser}">{{ data.row.updateUser ? $t('updatedBy') :$t('updated') }}</div>
      <span v-if="data.row.updateUser">
        <span class="segment__avatar">{{ data.row.updateUser.charAt(0).toUpperCase() }}</span>
        <span :class="{ 'text-capitalize': !isEmailValid(data.row.updateUser) }">
          {{ data.row.updateUser }}
        </span>
      </span>
      {{ $t('at') }} {{ formatDate(data.row.updateDateTime) }}
    </span>
  </div>
</template>

<script>
import {formatDate, isEmail} from '@/utils';

export default {
  name: 'DateColumn',
  props: {
    data: {
      type: [Array, Object],
    },
  },
  setup() {
    const isEmailValid = email => isEmail(email)

    return {
      isEmailValid,
      formatDate,
    }
  },
}
</script>

<style lang="scss">
.date-time__container {
  width: 400px !important;
  min-width: 350px !important;
}
  .block-elem--text {
    display: inline-block;
    max-width: 80px;
    width: 100%;
  }

  .update__user-text {
    width: unset !important;
  }

  .segment__avatar {
    text-transform: capitalize;
    border-radius: 50%;
    background: #E0E5EB;
    padding: 5px 8px;
    margin: 0 5px;
    color: #667C99;
    width: 30px;
  }
</style>

<template>
  <div class="toast-container">
    <img
      class="icon-container"
      width="24"
      height="24"
      v-on:click="closeResponse()"
      :src="
        type === 'success'
          ? require('@/assets/images/svgs/toast/toastsuccessicon.svg')
          : type === 'error'
          ? require('@/assets/images/svgs/toast/toasterroricon.svg')
          : type === 'info'
          ? require('@/assets/images/svgs/toast/toastinfoicon.svg')
          : ''
      "
    />
    <div class="info-container">
      <div class="title-container">{{ title }}</div>
      <div v-if="false" class="date-container">
        Date: {{ date.getDate() }}
        {{ date.toLocaleString("default", { month: "short" }) }},
        {{
          date.toLocaleString("default", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: () => "Hello World",
    },
    date: {
      type: Date,
      required: false,
      default: () => new Date(),
    },
  },
};
</script>

<style lang="scss">
@import 'vue-toastification/dist/index.css';

.Vue-Toastification__toast-component-body {
  display: flex;
  align-items: center;
}
.Vue-Toastification__toast {
  background-color: #fbfcfc;
  color: black;
  padding: 12px 20px 12px 12px;
}

.Vue-Toastification__toast--error {
  background-color: #fff !important;
  color: #fff;
}
.Vue-Toastification__toast--success {
  background-color: #fff !important;
  color: #fff;
}
.Vue-Toastification__toast--info {
  background-color: #fff !important;
  color: #fff;
}
.Vue-Toastification__close-button {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  padding-left: 20px;
  cursor: pointer;
  transition: 0.3s ease;
  align-items: center;
  color: #052d61 !important;
  opacity: 0.6;
  transition: visibility 0s, opacity 0.2s linear;
}

.toast-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon-container {
    margin-right: 13px;
    margin-top: 1px;
  }
  .info-container {
    display: flex;
    flex-direction: column;

    .title-container {
      font-size: 13px;
      font-weight: 500;
      color: #052d61;
      word-break: break-all;
    }
    .date-container {
      font-size: 13px;
      font-weight: 500;
      color: #667c99;
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return (_vm.prepareLinkItems || _vm.$route.meta.pageTitle) &&
    !_vm.isFullscreen
    ? _c(
        "div",
        {
          staticClass: "breadcrumb__custom",
          class: { visible__menu: _vm.isMenuVisible },
        },
        [
          _c(
            "b-row",
            { staticClass: "content-header" },
            [
              _c(
                "b-col",
                {
                  staticClass: "content-header-left mb-0",
                  attrs: { cols: "12", md: "12" },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "breadcrumbs-top" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h2",
                          { staticClass: "content-header-title pr-1 mb-0" },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$route.meta.pageTitle) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "breadcrumb-wrapper test" },
                          [
                            _c(
                              "b-breadcrumb",
                              _vm._l(_vm.prepareLinkItems, function (item) {
                                return _c(
                                  "b-breadcrumb-item",
                                  {
                                    key: item.text,
                                    class: item.to ? "clickable-link" : null,
                                    attrs: { active: item.active, to: item.to },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.text) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "read-more__container", class: _vm.classes },
    [
      _vm.to
        ? _c(
            "b-link",
            _vm._b({ attrs: { to: _vm.to } }, "b-link", { id: _vm.id }, false),
            [_vm._v(_vm._s(_vm.formattedText))]
          )
        : _c(
            "span",
            _vm._b(
              {
                class: { json__text: _vm.parseJson },
                on: { click: _vm.clickHandler },
              },
              "span",
              { id: _vm.id },
              false
            ),
            [_vm._v(_vm._s(_vm.formattedText))]
          ),
      _vm._v(" "),
      _vm.text?.length > _vm.breakpoint
        ? _c(
            "span",
            {
              staticClass: "read-more__toggle",
              on: { click: _vm.toggleReadMore },
            },
            [_vm._v(_vm._s(_vm.readMoreActivated ? "less" : "more"))]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-if="!isLoading">
    <div class="empty-content__icon">
      <img
        :src="require(`@/assets/images/svgs/products/${tableIcon}.svg`)"
        :alt="tableIcon"
      />
    </div>
    <div class="empty-content__info-text">
      <span v-if="isFiltering">{{ $t("noDataFound") }}</span>
      <span v-else>{{ emptyStateContent.noData }}...</span>
    </div>
    <div
      v-if="!isFiltering && clickHandler && clickHandler.canAdd"
      class="empty-content__action"
    >
      <span @click="clickHandler.action"
        >+ {{ emptyStateContent.addData }}</span
      >
    </div>
    <div
      v-if="isFiltering"
      class="empty-content__action"
    >
      <span @click="$emit('clear-filters')">
        {{ $t("clearFilters") }}
      </span>
    </div>
  </div>
  <LoadingBar v-else />
</template>

<script>
import LoadingBar from "@/components/shared/LoadingBar.vue";
import {
getEmptyStateContent,
getGroup,
getIcon,
} from "../table-utils/emptyTableFunctions";

export default {
  name: "TableEmptyState",
  emits: ['clear-filters'],
  components: {
    LoadingBar,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    isFiltering: {
      type: Boolean,
    },
    typeOfTable: {
      type: String,
    },
    clickHandler: {
      type: Object,
    },
  },
  setup(props) {
    const tableGroup = getGroup(props.typeOfTable);
    const tableIcon = getIcon(tableGroup);
    const emptyStateContent = getEmptyStateContent(props.typeOfTable);

    return {
      tableIcon,
      emptyStateContent,
    };
  },
};
</script>

<style lang="scss">
.empty-content__icon {
  text-align: center;
  color: #1d79f2;
  margin-bottom: 16px;

  img {
    width: 28px;
    height: 28px;
  }
}

.empty-content__action {
  margin-top: 8px;
  text-align: center;
  cursor: pointer;

  span {
    color: #1d79f2 !important;
  }
}
</style>
